import React, { useEffect, useRef, useState } from "react";
import holdersIcon from "../../../media/v3_holdersIcon.png";
import { SERVER_ENDPOINT } from "../../../utils/constants";
import { formatNumberWithCommas } from "../../../utils/formatedNumberWithComas";
const TonComponent = ({ setTokenPrice }) => {
  const [totalUser, setTotalUsers] = useState(2350);
  const [totalUserGame, setTotalUsersGame] = useState(2350);
  const [totalTransactions, setTotalTransactions] = useState(0);
  const [sol, setSol] = useState(650.3);
  const [ton, setTon] = useState(15523.2);
  const currentPrice = useRef(0);
  const twentyHourPrice = useRef(0);
  const thirtyDayPrice = useRef(0);
  const ytdPrice = useRef(0);
  const lpSolPrice = useRef(0);
  const usdTonPrice = useRef(0);

  useEffect(() => {
    async function totalUsersDapp() {
      try {
        const response = await fetch(
          `https://dapp-prodapi.pepefrogbar.com/total-users`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        const parsedResponse = await response.json();

        setTotalUsers(parsedResponse.totalUsers);
      } catch (error) {
        console.log(error);
      }
    }

    async function totalUsersGame() {
      try {
        const response = await fetch(
          `https://game-prodapi.pepefrogbar.com/v1/appmetric/packageOwners`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        const parsedResponse = await response.json();
        setTotalUsersGame(parsedResponse.data.value);
      } catch (error) {
        console.log(error);
      }
    }

    async function tokenPriceVariation() {
      try {
        const response = await fetch(
          `https://dapp-prodapi.pepefrogbar.com/token-variation`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        const parsedResponse = await response.json();
        currentPrice.current = parsedResponse.result.currentPrice;
        twentyHourPrice.current = parsedResponse.result.twentyFourPrice;
        thirtyDayPrice.current = parsedResponse.result.thirtyDayPrice;
        ytdPrice.current = parsedResponse.result.YTDPrice;
      } catch (error) {
        console.log(error);
      }
    }

    async function solPrice() {
      try {
        const response = await fetch(
          `https://dapp-prodapi.pepefrogbar.com/public-token-price`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        const parsedResponse = await response.json();
        setSol(parsedResponse.solPrice);
        setTokenPrice(parsedResponse.tokenPrice);
        console.log("lpSolBalance:", parsedResponse.lpSolBalance);
        lpSolPrice.current = parsedResponse.lpSolBalance;
      } catch (error) {
        console.log(error);
      }
    }
    async function tonPrice() {
      try {
        const response = await fetch(
          `${SERVER_ENDPOINT}/v1/appmetric/packages/value`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        const parsedResponse = await response.json();
        setTon(parsedResponse.data.balanceTon);
        usdTonPrice.current = parsedResponse.data.usdValue;
      } catch (error) {
        console.log(error);
      }
    }

    async function fetchTotalTransactions() {
      try {
        const response = await fetch(
          `https://game-prodapi.pepefrogbar.com/v1/transaction/successcount`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        const parsedResponse = await response.json();
        setTotalTransactions(parsedResponse.count);
      } catch (error) {
        console.log(error);
      }
    }

    solPrice();
    tonPrice();
    tokenPriceVariation();
    totalUsersDapp();
    totalUsersGame();
    fetchTotalTransactions();
  }, []);

  // console.log(ton, sol, totalUser);

  return (
    <div>
      <div className="solanaDiv" style={{ margin: "0px 25px" }}>
        <div
          style={{
            display: "flex",
            gap: "10px",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            marginBottom: "15px",
          }}
        >
          <div
            style={{
              border: "1px solid #FAE469CC ",
              borderRadius: "15px",
              width: "100%",
              textAlign: "center",
              background: "#1C1C1CBF",
              padding: "4px 0px",
              height: "70px",
            }}
          >
            <span
              style={{
                fontSize: "18px",
                color: "#E7E1E1",
                fontFamily: "Roboto",
              }}
            >
              Solana LP
            </span>{" "}
            <br />
            <span
              style={{
                fontSize: "17px",
                fontFamily: "Roboto",
              }}
            >
              {lpSolPrice.current.toFixed(2)} SOL
            </span>{" "}
            <br />
            <span
              style={{
                fontSize: "14px",
                fontFamily: "Roboto",
              }}
            >
              (${formatNumberWithCommas((sol * lpSolPrice.current).toFixed(2))})
            </span>
          </div>
          <div
            style={{
              border: "1px solid #FAE469CC ",
              borderRadius: "15px",
              width: "100%",
              textAlign: "center",
              background: "#1C1C1CBF",
              padding: "4px 0px",
              height: "70px",
            }}
          >
            <span
              style={{
                fontSize: "17px",
                color: "#E7E1E1",
                fontFamily: "Roboto",
              }}
            >
              Ton LP
            </span>{" "}
            <br />
            <span
              style={{
                fontSize: "16px",
                color: "#E7E1E1",
                fontFamily: "Roboto",
              }}
            >
              {ton.toFixed(2)} TON
            </span>{" "}
            <br />
            <span
              style={{
                fontSize: "14px",
                color: "#E7E1E1",
                fontFamily: "Roboto",
              }}
            >
              (${formatNumberWithCommas(usdTonPrice.current.toFixed(2))})
            </span>
          </div>
        </div>
        <div
          style={{
            border: "1px solid #FAE469CC ",
            padding: "5px",
            borderRadius: "15px",
            display: "flex",
            alignItems: "center",
            gap: "20px",
            height: "65px",
            textAlign: "center",
          }}
        >
          <img
            src={holdersIcon}
            alt=""
            style={{
              marginLeft: "20px",
              width: "100px",
              height: "100px",
            }}
          />
          <div>
            <span
              style={{
                fontSize: "20px",
                color: "#E7E1E1",
                fontFamily: "Roboto",
                textAlign: "center",
                marginLeft: "16px",
              }}
            >
              Total Holders
            </span>{" "}
            <br />
            <span style={{ fontSize: "19px", fontFamily: "Roboto" }}>
              {totalTransactions * 3 + totalUser + 100}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TonComponent;
