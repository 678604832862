import React, { useContext, useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { TailSpin } from "react-loader-spinner";
import { AuthContext } from "../../../context/AuthContext";
import comingSoonIcon from "../../../media/Coming_Soon.gif";
import v3SettingsImg from "../../../media/v3_gearIcon.png";
import { SERVER_ENDPOINT } from "../../../utils/constants";
import "./SettingsPopup.css";

const SettingsPopup = ({ isOpen, onClose }) => {
  const [showPopup, setShowPopup] = useState(true);
  const [body, setBody] = useState("");
  const [subject, setSubject] = useState("");
  const [loading, setLoading] = useState(false);

  const { jwt } = useContext(AuthContext);

  const popupRef = useRef(null);
  useEffect(() => {
    if (isOpen) {
      // Delay adding the "show" class to ensure smooth animation
      setTimeout(() => setShowPopup(true), 50);
    } else {
      setShowPopup(false);
    }
  }, [isOpen]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  async function handleSupportSubmit() {
    try {
      setLoading(true);
      const resp = await fetch(`${SERVER_ENDPOINT}/v1/user/contact`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwt}`,
        },
        body: JSON.stringify({ subject, text: body }),
      });

      if (resp.status === 200) toast.success("Sent");
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      setBody("");
      setSubject("");
    }
  }

  return (
    <div className="popup-overlay">
      <div
        ref={popupRef}
        className={`popup-container ${showPopup ? "show" : ""}`}
        style={{
          backgroundColor: "#1D1C1D",
          borderTopLeftRadius: "60px",
          borderTopRightRadius: "60px",
          borderTop: "5px solid yellow",
          position: "relative",
        }}
      >
        <div className="settings_contentDiv" style={{ padding: "20px" }}>
          <div className="titleBox">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "5px",
              }}
            >
              <img src={v3SettingsImg} alt="" className="settingsImg" />
              <span className="iconTitle">SETTINGS</span>
            </div>
            <span className="subTitle">Game setting preference</span>
          </div>

          <div className="dropdown_wrapper_Settings">
            <div>
              <span className="selectLangleftTitle">Preferred Language</span>
            </div>
            <div id="parent_langswitch">
              {/* <LanguageSwitcher /> */}
              <img
                alt=""
                src={comingSoonIcon}
                width={"150px"}
                height={"30px"}
                style={{ objectFit: "cover" }}
              />
            </div>
          </div>

          {/* <div className="sound_settings">
            <span className="soundText">Sound& vibration</span>
            <div className="soundsButton">
              <div className="onButton soundActive">ON</div>
              <div className="offButton">OFF</div>
            </div>
          </div> */}

          <div className="contactSupport">
            <p className="supportTitle">Contact support</p>
            <div className="formSection">
              <div className="subjectInput">
                <span className="subjectText">Subject: </span>
                <input
                  value={subject}
                  onChange={(e) => setSubject(e.target.value)}
                  type="text"
                  className="subjectField"
                />
              </div>
              <div className="textAreaBox">
                <textarea
                  placeholder="Text"
                  rows="4"
                  cols="50"
                  type="are"
                  className="subjectField textAreaInput"
                  value={body}
                  onChange={(e) => setBody(e.target.value)}
                  maxLength={100}
                />
              </div>
              <div className="buttonSubmit">
                <button
                  disabled={loading}
                  onClick={handleSupportSubmit}
                  className="sendButton"
                >
                  {loading ? (
                    <TailSpin
                      visible={true}
                      height="20"
                      width="20"
                      color="white"
                      ariaLabel="tail-spin-loading"
                      radius="1"
                      wrapperStyle={{}}
                      wrapperClass=""
                    />
                  ) : (
                    "send"
                  )}
                </button>
                <span>{body.length}/100</span>
              </div>
            </div>
          </div>
          <div>
            <button className="btn_Notification" onClick={() => onClose()}>
              Save & Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettingsPopup;
