// useTelegramBackButton.js
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const useTelegramBackButton = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const WebApp = window.Telegram.WebApp;
    if (WebApp) {
      WebApp.ready();
      if (window.location.pathname !== "/") {
        WebApp.BackButton.show();
        WebApp.BackButton.onClick(() => {
          navigate("/?noload=true"); 
        });
      } else {
        WebApp.BackButton.hide(); 
      }
    }
  }, [navigate]);
};

export default useTelegramBackButton;
