import moment from "moment";
import React, { createContext, useContext, useEffect, useState } from "react";
import {
  hotelIdxToNameMapping,
  hotelLevelDetails,
  SERVER_ENDPOINT,
} from "../utils/constants";
import { AuthContext } from "./AuthContext";
import { UserContext } from "./UserContext";

export const HotelContext = createContext();

export const HotelContextProvider = ({ children }) => {
  const { jwt } = useContext(AuthContext);
  const { setUserCredits } = useContext(UserContext);
  const [userHotels, setUserHotels] = useState([]);
  const [hotelCredits, setHotelCredits] = useState(0);
  async function fetchUserHotel() {
    try {
      const resp = await fetch(`${SERVER_ENDPOINT}/v1/hotel/hotel-details`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwt}`,
        },
      });

      if (resp.status === 200) {
        const parsedRes = await resp.json();

        const hotels = parsedRes.hotelLevel.map((level, idx) => {
          return {
            hotelId: idx,
            hotelName: hotelIdxToNameMapping[idx],
            isOnUpgrade: parsedRes.isOnUpgrade[idx],
            isUnlocked: parsedRes.isUnLocked[idx],
            upgradeStartAt: parsedRes.timeOfStart[idx],
            hotelLevel: level,
          };
        });
        setUserHotels(hotels);
      }
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    if (jwt) {
      fetchUserHotel();
    }
  }, [jwt]);

  useEffect(() => {
    // if some hotel is updating -> inc the credits per second
    const onUpgradeHotels = userHotels.filter((hotel) => hotel.isOnUpgrade);
    const perSecondInc = onUpgradeHotels.map((hotel) => {
      const { duration, invest, roi } = hotelLevelDetails[hotel.hotelLevel];
      return ((1 + roi / 100) * invest) / (duration * 60);
    });
    const alreadyGivenCredits = onUpgradeHotels
      .map((hotel, idx) => {
        const startTimeStamp = moment.utc(hotel.upgradeStartAt);
        return (
          moment().utc().diff(startTimeStamp, "seconds") * perSecondInc[idx]
        );
      })
      .reduce((prev, curr) => prev + curr, 0);
    const perSecondCredits = perSecondInc.reduce(
      (prev, curr) => prev + curr,
      0
    );
    setHotelCredits(alreadyGivenCredits);
    const i = setInterval(() => {
      setHotelCredits((credits) => credits + perSecondCredits);
    }, 1000);

    return () => clearInterval(i);
  }, [userHotels]);
  return (
    <HotelContext.Provider
      value={{ userHotels, setUserHotels, hotelCredits, fetchUserHotel }}
    >
      {children}
    </HotelContext.Provider>
  );
};
