import rank1Icon from "../media/rank-icon.png";
const MINIAPP_LINK = "https://t.me/pepefrogbar_bot/pepefrogbar";
const TON_EXPLORER = "https://toncenter.com/api/v2";

// USE FOR PROD
const SERVER_ENDPOINT = "https://game-prodapi.pepefrogbar.com";

// USE FOR DEV
// const SERVER_ENDPOINT = "https://game-devapi.pepefrogbar.com";
const WEBSOCKET_ENDPOINT = "wss://game-prodapi.pepefrogbar.com/websockets";

// USE FOR LOCAL
// const SERVER_ENDPOINT = "http://localhost:4000";
// const WEBSOCKET_ENDPOINT = "ws://localhost:4000/websockets";

// const DAPP_PRODUCTION_ENDPOINT = "https://dapp-prodapi.pepefrogbar.com";
// const DAPP_DEV_ENDPOINT = "https://dapp-devapi.pepefrogbar.com";
const TON_EXPLORER_KEY =
  "ec2cd059d5d4ec929eaa7936442b3938cce4792a6834faa229c2100060f37c67";

const rankToStringMapping = {
  0: "ROOKIE",
  1: "BRONZE",
  2: "SILVER",
  3: "GOLD",
  4: "PLATINUM",
  5: "DIAMOND",
  6: "BLUE DIAMOND",
  7: "CROWN DIAMOND",
};

const rankToStringMappingTest = [
  {
    id: 0,
    rankName: "ROOKIE",
    icon: rank1Icon,
    energyHour: 3600,
    invite: 0,
  },
  {
    id: 1,
    rankName: "BRONZE",
    icon: rank1Icon,
    energyHour: 4000,
    invite: 3,
  },
  {
    id: 2,
    rankName: "SILVER",
    icon: rank1Icon,
    energyHour: 4500,
    invite: 10,
  },
  {
    id: 3,
    rankName: "GOLD",
    icon: rank1Icon,
    energyHour: 5000,
    invite: 25,
  },
  {
    id: 4,
    rankName: "PLATINUM",
    icon: rank1Icon,
    energyHour: 7500,
    invite: 50,
  },
  {
    id: 5,
    rankName: "DIAMOND",
    icon: rank1Icon,
    energyHour: 10000,
    invite: 100,
  },
  {
    id: 6,
    rankName: "BLUE DIAMOND",
    icon: rank1Icon,
    energyHour: 12500,
    invite: 500,
  },
  {
    id: 7,
    rankName: "CROWN DIAMOND",
    icon: rank1Icon,
    energyHour: 15000,
    invite: 1000,
  },
];

const energyRankMapping = {
  0: 3600,
  1: 4000,
  2: 4500,
  3: 5000,
  4: 7500,
  5: 10000,
  6: 12500,
  7: 15000,
};

const clubIdxToNameMapping = {
  0: "RIO",
  1: "MOSCOW",
  2: "SINGAPORE",
  3: "MUMBAI",
  4: "DUBAI",
  5: "BEIJING",
  6: "ISTANBUL",
  7: "PARIS",
  8: "LONDON",
  9: "ROME",
};

const motorIdxToNameMapping = {
  0: "Motorbike",
  1: "SUVs",
  2: "Sports",
  3: "Luxury",
  4: "Jets",
  5: "Yacht",
};
const hotelIdxToNameMapping = {
  0: "China",
  1: "Greece",
  2: "Thailand",
  3: "Holland",
  4: "Hawaii",
  5: "Nigeria",
  6: "Egypt",
  7: "Austria",
  8: "Spain",
  9: "USA",
};

const userClubToRankNameMapping = {
  1: "INTERN",
  2: "ASSOCIATE",
  3: "MANAGER",
  4: "SM",
  5: "VP",
  6: "CFO",
  7: "CMO",
  8: "CEO",
};

const userClubRankToStarsMapping = {
  1: 0,
  2: 3,
  3: 8,
  4: 15,
  5: 30,
  6: 56,
  7: 96,
  8: 135,
};

const levelCreditPrice = {
  0: 0,
  1: 10000,
  2: 20000,
  3: 50000,
  4: 100000,
  5: 200000,
  6: 500000,
  7: 750000,
  8: 1000000,
  9: 1500000,
};

const clubLevelDetails = {
  0: { invest: 50000, roi: 10, duration: 10, stars: 1 },
  1: { invest: 75000, roi: 10, duration: 25, stars: 2 },
  2: { invest: 150000, roi: 10, duration: 45, stars: 3 },
  3: { invest: 300000, roi: 10, duration: 90, stars: 4 },
  4: { invest: 500000, roi: 10, duration: 180, stars: 5 },
  5: { invest: 600000, roi: 25, duration: 300, stars: 6 },
  6: { invest: 700000, roi: 25, duration: 480, stars: 7 },
  7: { invest: 800000, roi: 25, duration: 600, stars: 8 },
  8: { invest: 900000, roi: 25, duration: 720, stars: 9 },
  9: { invest: 1250000, roi: 25, duration: 900, stars: 10 },
  10: { invest: 1500000, roi: 50, duration: 1200, stars: 11 },
  11: { invest: 2500000, roi: 50, duration: 1440, stars: 12 },
  12: { invest: 3000000, roi: 50, duration: 1800, stars: 13 },
  13: { invest: 3500000, roi: 50, duration: 2100, stars: 14 },
  14: { invest: 5000000, roi: 50, duration: 2400, stars: 15 },
  15: { invest: 7500000, roi: 100, duration: 2700, stars: 16 },
  16: { invest: 10000000, roi: 100, duration: 3000, stars: 17 },
  17: { invest: 15000000, roi: 100, duration: 3300, stars: 18 },
  18: { invest: 20000000, roi: 100, duration: 3600, stars: 19 },
  19: { invest: 30000000, roi: 100, duration: 4320, stars: 20 },
};
const motorLevelDetails = {
  1: { invest: 50000, roi: 10, duration: 10, stars: 1 },
  2: { invest: 75000, roi: 10, duration: 25, stars: 2 },
  3: { invest: 150000, roi: 10, duration: 45, stars: 3 },
  4: { invest: 300000, roi: 10, duration: 90, stars: 4 },
  5: { invest: 500000, roi: 10, duration: 180, stars: 5 },
  6: { invest: 600000, roi: 25, duration: 300, stars: 6 },
  7: { invest: 700000, roi: 25, duration: 480, stars: 7 },
  8: { invest: 800000, roi: 25, duration: 600, stars: 8 },
  9: { invest: 900000, roi: 25, duration: 720, stars: 9 },
  10: { invest: 1250000, roi: 25, duration: 900, stars: 10 },
  11: { invest: 1500000, roi: 50, duration: 1200, stars: 11 },
  12: { invest: 2500000, roi: 50, duration: 1440, stars: 12 },
  13: { invest: 3000000, roi: 50, duration: 1800, stars: 13 },
  14: { invest: 3500000, roi: 50, duration: 2100, stars: 14 },
  15: { invest: 5000000, roi: 50, duration: 2400, stars: 15 },
  16: { invest: 7500000, roi: 100, duration: 2700, stars: 16 },
  17: { invest: 10000000, roi: 100, duration: 3000, stars: 17 },
  18: { invest: 15000000, roi: 100, duration: 3300, stars: 18 },
  19: { invest: 20000000, roi: 100, duration: 3600, stars: 19 },
  20: { invest: 30000000, roi: 100, duration: 4320, stars: 20 },
};

const hotelLevelDetails = {
  1: { invest: 50000, roi: 10, duration: 10, stars: 1 },
  2: { invest: 75000, roi: 10, duration: 25, stars: 2 },
  3: { invest: 150000, roi: 10, duration: 45, stars: 3 },
  4: { invest: 300000, roi: 10, duration: 90, stars: 4 },
  5: { invest: 500000, roi: 10, duration: 180, stars: 5 },
  6: { invest: 600000, roi: 25, duration: 300, stars: 6 },
  7: { invest: 700000, roi: 25, duration: 480, stars: 7 },
  8: { invest: 800000, roi: 25, duration: 600, stars: 8 },
  9: { invest: 900000, roi: 25, duration: 720, stars: 9 },
  10: { invest: 1250000, roi: 25, duration: 900, stars: 10 },
  11: { invest: 1500000, roi: 50, duration: 1200, stars: 11 },
  12: { invest: 2500000, roi: 50, duration: 1440, stars: 12 },
  13: { invest: 3000000, roi: 50, duration: 1800, stars: 13 },
  14: { invest: 3500000, roi: 50, duration: 2100, stars: 14 },
  15: { invest: 5000000, roi: 50, duration: 2400, stars: 15 },
  16: { invest: 7500000, roi: 100, duration: 2700, stars: 16 },
  17: { invest: 10000000, roi: 100, duration: 3000, stars: 17 },
  18: { invest: 15000000, roi: 100, duration: 3300, stars: 18 },
  19: { invest: 20000000, roi: 100, duration: 3600, stars: 19 },
  20: { invest: 30000000, roi: 100, duration: 4320, stars: 20 },
};

const okxTaskId = "roczXppTg7MsMzC_wZgHr"

export {
  MINIAPP_LINK,
  SERVER_ENDPOINT,
  TON_EXPLORER,
  TON_EXPLORER_KEY,
  WEBSOCKET_ENDPOINT,
  clubIdxToNameMapping,
  clubLevelDetails,
  energyRankMapping,
  motorIdxToNameMapping,
  motorLevelDetails,
  levelCreditPrice,
  hotelIdxToNameMapping,
  hotelLevelDetails,
  rankToStringMapping,
  rankToStringMappingTest,
  userClubRankToStarsMapping,
  userClubToRankNameMapping,
  okxTaskId
};
