import React, { useContext, useEffect, useState } from "react";
import v3_YoutubeIcon from "../../../media/V3_youtube.png";
import v3_TgLogo from "../../../media/v3_tgLogo.png";
import v3_xLogo from "../../../media/v3_xLogo.png";
import v3_Arrrow from "../../../media/V3_arrow.png";
import { SERVER_ENDPOINT } from "../../../utils/constants";
import { UserContext } from "../../../context/UserContext";
import { AuthContext } from "../../../context/AuthContext";
import { getMobileOperatingSystem } from "../../../utils/helpers";
import vr_greentick from "../../../media/v3green-tick.png";

import Partners from "../Partners/Partners";

import YoutubeAnimation from "../../../media/Youtube.gif";
import XAnimation from "../../../media/X.gif";
import TelegramAnimation from "../../../media/Telegram.gif";

function screenImg(taskName = "") {
  const splitted = taskName.split(" ");

  if (splitted.indexOf("YOUTUBE") !== -1) {
    return (
      <img
        src={YoutubeAnimation}
        alt="Youtube Animation"
        height="50px"
        width="50px"
        autoPlay
        loop
        muted
        playsInline
      />
    );
  } else if (splitted.indexOf("X") !== -1) {
    return (
      <img
        src={XAnimation}
        alt="X Animation"
        height="50px"
        width="50px"
        autoPlay
        loop
        muted
        playsInline
      />
    );
  } else {
    return (
      <img
        src={TelegramAnimation}
        alt="Telegram Animation"
        height="50px"
        width="50px"
        autoPlay
        loop
        muted
        playsInline
      />
    );
  }
}

function screenColor(taskName = "") {
  const splitted = taskName.split(" ");
  if (splitted.indexOf("YOUTUBE") !== -1) return "#EA4025";
  else if (splitted.indexOf("X") !== -1) return "black";
  else return "#2A7CD4";
}

const SocialAccounts = ({ screen, title }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [tasks, setTasks] = useState([]);
  const [isRedeemLoading, setIsRedeemLoading] = useState(false);
  const { setUserCredits } = useContext(UserContext);
  const { jwt } = useContext(AuthContext);

  async function handleTaskCompletion(taskID, redirectUrl) {
    try {
      setIsRedeemLoading(true);
      const response = await fetch(`${SERVER_ENDPOINT}/v1/task/redeem`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwt}`,
        },
        body: JSON.stringify({ taskID }),
      });
      const parsedResponse = await response.json();
      if (parsedResponse.status === "SUCCESS") {
        setTimeout(
          () =>
            setTasks((tasks) => {
              return tasks.map((task) => {
                if (task.taskID === parsedResponse.data.taskID) {
                  task.completed = true;
                }
                return task;
              });
            }),
          5000
        );
        setUserCredits(
          (credits) => credits + parseInt(parsedResponse.data.reward)
        );
      } else throw new Error("Failed to redeem task");
    } catch (error) {
      console.log(error);
    } finally {
      setIsRedeemLoading(false);
      if (getMobileOperatingSystem() === "iOS") {
        window.location.href = redirectUrl;
      } else {
        window.open(redirectUrl);
      }
    }
  }

  useEffect(() => {
    async function fetchTasks() {
      try {
        setIsLoading(true);
        const response = await fetch(`${SERVER_ENDPOINT}/v1/task/list`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwt}`,
          },
          body: JSON.stringify({ screen: "social" }),
        });
        const parsedResponse = await response.json();
        setTasks(parsedResponse.data);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    }
    fetchTasks();
  }, [screen]);

  return (
    <div>
      <span
        style={{
          textAlign: "left",
          fontWeight: "bold",
          fontSize: "25px",
          letterSpacing: "1px",
          color: "white",
        }}
      >
        Featured Tasks
      </span>
      {tasks.map((task) => (
        <div
          onClick={() => handleTaskCompletion(task.taskID, task.url)}
          className="task_Card"
        >
          <div className="taskImg">
            {screenImg(task.name)} {/* Render the dynamic image or video */}
          </div>
          <div className="task_text">
            <span className="text_1stSpan">{task.name}</span> <br />
            <span className="text_2ndSpan">Official channel</span> <br />
            <span className="text_3drdSpan">
              Status:
              <span style={{ color: "#BFA75D" }}>
                {task.completed ? " Done" : " Available"}
              </span>
            </span>
          </div>
          <div className="task_Reward">
            <span
              style={{
                color: "#BFA75D",
                fontSize: "22px",
                letterSpacing: "1px",
              }}
            >
              + {task.reward / 1000}K
            </span>{" "}
            <br />
            <span>Task Reward</span>
          </div>
          <div className="task_Icon" style={{ textAlign: "right" }}>
            <img
              src={task.completed ? vr_greentick : v3_Arrrow}
              alt=""
              style={
                task.completed
                  ? {
                      width: "50%",
                      height: "50%",
                    }
                  : {}
              }
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export default SocialAccounts;
