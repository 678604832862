import { useSpring } from "@react-spring/web";
import React, { useContext, useState } from "react";
import { ClubContext } from "../../context/ClubContext";
import { HotelContext } from "../../context/HotelContext";
import { MotorContext } from "../../context/MotorContext";
import { UserContext } from "../../context/UserContext";
import "./bigWin.css";

const BIgWinHome = () => {
  const [visible, setVisible] = useState(false);
  const {
    showConnectWallet,
    setShowConnectWallet,
    user,
    userCredits,
    setUserCredits,
  } = useContext(UserContext);
  const { clubCredits } = useContext(ClubContext);
  const { motorCredits } = useContext(MotorContext);
  const { hotelCredits } = useContext(HotelContext);
  const topAnimation = useSpring({
    from: { transform: "translateY(-100%)", opacity: 0 },
    to: async (next, cancel) => {
      await next({ opacity: 1 });
      await next({
        transform: visible ? "translateY(0%)" : "translateY(90%)",
      });
    },
    config: { duration: 300 },
  });
  return (
    <div className="bigWin_container">
      <h1>Hello world</h1>
    </div>
  );
};

export default BIgWinHome;
