import { TonConnectUIProvider } from "@tonconnect/ui-react";
import React from "react";
import ReactDOM from "react-dom/client";
import { Toaster } from "react-hot-toast";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import App from "./App";
import BIgWinHome from "./app/BIgWin/BIgWinHome";
import Club from "./app/ClubPage/Club";
import MiniGameIndex from "./app/Mini-Game/MIniGameIndex/MiniGameIndex";
import MiniGame2 from "./app/Mini-Game/MiniGame2/MiniGame2";
import Faq from "./app/PlayPage/FaqModal/Faq";
import Friend from "./app/PlayPage/FriendModal/Friend";
import BecameYourOwnBoss from "./app/PlayPage/OwnBoss/BecameYourOwnBoss";
import PersonalStat from "./app/PlayPage/PersonalStatModal/Personal";
import PrivateSale from "./app/PlayPage/PrivateSaleModal/privatesale";
import Profile from "./app/PlayPage/ProfileModal/profile";
import Puzzle from "./app/PlayPage/PuzzleModel/puzzle";
import Ranking from "./app/PlayPage/RankingModal/ranking";
import Stats from "./app/PlayPage/StatsModal/Stats";
import Tasks from "./app/PlayPage/TasksModal/tasks";
import PrivacyPolicy from "./app/Privacy-Policy/PrivacyPolicy";
import Unsupported from "./app/UnsupportedPage/Unsupported";
import Wallet from "./app/wallet/Wallet";
import { AuthContextProvider } from "./context/AuthContext";
import { ClubContextProvider } from "./context/ClubContext";
import { HotelContextProvider } from "./context/HotelContext";
import { MotorContextProvider } from "./context/MotorContext";
import { UserContextProvider } from "./context/UserContext";
import WalletContextProvider from "./context/WalletContext";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

const manifestUrl =
  "https://raw.githubusercontent.com/ton-community/tutorials/main/03-client/test/public/tonconnect-manifest.json";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App key="page1" />,
  },
  {
    path: "/unsupported",
    element: <Unsupported key="page2" />,
  },
  {
    path: "/stats",
    element: <Stats key="page3" />,
  },
  {
    path: "/task",
    element: <Tasks key="page4" />,
  },
  {
    path: "/rank",
    element: <Ranking key="page5" />,
  },
  {
    path: "/personal",
    element: <PersonalStat key="page6" />,
  },
  {
    path: "/profile",
    element: <Profile key="page7" />,
  },
  {
    path: "/wallet",
    element: <Wallet key="page8" />,
  },
  {
    path: "/friend",
    element: <Friend key="page9" />,
  },
  {
    path: "/friend?bonus=true",
    element: <Friend key="page9" />,
  },
  {
    path: "/faq",
    element: <Faq key="page10" />,
  },
  {
    path: "/privatesale",
    element: <PrivateSale key="page11" />,
  },
  {
    path: "/puzzle",
    element: <Puzzle key="page12" />,
  },
  {
    path: "/privacy-policy",
    element: <PrivacyPolicy key="page11" />,
  },
  {
    path: "/mini-game",
    element: <MiniGameIndex key="page13" />,
  },
  {
    path: "/mini-game-2",
    element: <MiniGame2 key="page14" />,
  },
  {
    path: "/ceo",
    element: <BecameYourOwnBoss />,
  },
  {
    path: "/Club",
    element: <Club />,
  },
  {
    path: "/BigWin",
    element: <BIgWinHome />,
  },
]);

// Custom component to handle back button visibility
// const BackButtonHandler = () => {
//   const location = useLocation();
//   console.log("Initial location:", window.location.href);

//   useEffect(() => {
//     if (window.Telegram.WebApp) {
//       const WebApp = window.Telegram.WebApp;
//       WebApp.ready();

//       // Conditionally show/hide back button based on the location
//       if (location.pathname !== "/") {
//         WebApp.BackButton.show();
//         WebApp.BackButton.onClick(() => {
//           console.log("Back button clicked on page:", location.pathname);
//           window.history.back();
//         });
//       } else {
//         WebApp.BackButton.hide();
//       }
//     }
//   }, [location]);

//   return null;
// };

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <TonConnectUIProvider manifestUrl={manifestUrl}>
      <UserContextProvider>
        <AuthContextProvider>
          <ClubContextProvider>
            <MotorContextProvider>
              <HotelContextProvider>
                <WalletContextProvider>
                  <Toaster toastOptions={{ className: "toaster-container" }} />
                  <RouterProvider router={router} />
                </WalletContextProvider>
              </HotelContextProvider>
            </MotorContextProvider>
          </ClubContextProvider>
        </AuthContextProvider>
      </UserContextProvider>
    </TonConnectUIProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
