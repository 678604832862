import { useTonAddress, useTonConnectUI } from "@tonconnect/ui-react";
import React, {
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import toast from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { UserContext } from "../../context/UserContext";
import { WalletContext } from "../../context/WalletContext";
import tonIcon from "../../media/V3_Ton.png";
import walletIcon from "../../media/V3_wallet.png";
import rankImg_la from "../../media/crownDiamond.png";
import rankImg_ca from "../../media/diamond.png";
import rankImg_es from "../../media/gold.png";
import levelImg_la from "../../media/level10.png";
import levelImg_es from "../../media/level3.png";
import levelImg_ca from "../../media/level7.png";
import pepeIcon from "../../media/pepe_newLogo.jpg";
import v3_puchaseArrow from "../../media/v3_puchaseNowIcon.png";
import useTelegramBackButton from "../../utils/backButton";
import {
  SERVER_ENDPOINT,
  TON_EXPLORER,
  TON_EXPLORER_KEY,
} from "../../utils/constants";
import { formatNumberWithCommas } from "../../utils/formatedNumberWithComas";
import "./wallet.css";

const Wallet = React.forwardRef(({ isOpen, onClose }, ref) => {
  useTelegramBackButton();
  const [walletConnect, setWalletConnect] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);
  const [tonConnectUI, setOptions] = useTonConnectUI();
  const [tonUsdValue, setTonUsdValue] = useState(0);
  const [tokenPrice, setTokenPrice] = useState(0);
  const { jwt } = useContext(AuthContext);
  const {
    user,
    userCredits,
    setShowConnectWallet,
    showConnectWallet,
    setUser,
  } = useContext(UserContext);
  const {
    handleActiveWalletDisconnect,
    handleOkxConnect,
    handleTonConnect,
    activeWallet,
    activeWalletAddress,
  } = useContext(WalletContext);
  const navigate = useNavigate();
  const location = useLocation();
  const userAddress = useTonAddress();
  const [balance, setBalance] = useState(0);
  const [userPackages, setUserPackages] = useState([]);
  const [congratsPopup, setCongratsPopup] = useState(false);
  const [userOwnedPackages, setUserOwnedPackages] = useState([]);
  const [walletConnected, setWalletConnected] = useState(false);

  console.log("wallet", activeWallet);

  const manifestUrl =
    "https://raw.githubusercontent.com/ton-community/tutorials/main/03-client/test/public/tonconnect-manifest.json";

  const imageMappings = [
    {
      id: 3,
      rankImg: rankImg_es,
      levelImg: levelImg_es,
    },
    {
      id: 5,
      rankImg: rankImg_ca,
      levelImg: levelImg_ca,
    },
    {
      id: 7,
      rankImg: rankImg_la,
      levelImg: levelImg_la,
    },
  ];
  const [lastTxnSuccess, setLastTxnSuccess] = useState();
  useEffect(() => {
    async function fetchUserOwnedPackages() {
      try {
        const res = await fetch(`${SERVER_ENDPOINT}/v1/user/packages`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        });
        const parsedRes = await res.json();
        if (parsedRes.status === "SUCCESS")
          setUserOwnedPackages(parsedRes.data);
        else throw new Error("Failed to fetch packages");
      } catch (error) {
        console.log(error);
      }
    }
    fetchUserOwnedPackages();
  }, [lastTxnSuccess]);
  useEffect(() => {
    async function fetchPackages() {
      try {
        const res = await fetch(`${SERVER_ENDPOINT}/v1/package`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        });
        const parsedRes = await res.json();
        if (parsedRes.status === "SUCCESS") setUserPackages(parsedRes.data);
        else throw new Error("Failed to fetch packages");
      } catch (error) {
        console.log(error);
      }
    }

    async function fetchTokenPrice() {
      try {
        const res = await fetch(
          "https://6mmcf0xaoi.execute-api.eu-central-1.amazonaws.com/dev/public-token-price",
          { method: "GET" }
        );
        const parsedRes = await res.json();
        setTokenPrice(parseFloat(parsedRes.tokenPrice.toFixed(9)));
      } catch (error) {
        console.log(error);
      }
    }
    async function getTonUsdValue() {
      try {
        const response = await fetch(`${SERVER_ENDPOINT}/price/ton/usd`, {
          method: "GET",
        });
        const parsedResponse = await response.json();
        if (parsedResponse.status === "FAILED")
          throw new Error("Failed to fetch usd-ton price");
        setTonUsdValue(parseFloat(parsedResponse.data.usd));
      } catch (error) {
        console.log(error);
      }
    }
    fetchPackages();
    getTonUsdValue();
    fetchTokenPrice();
    const i1 = setInterval(getTonUsdValue, 1000 * 60 * 5);
    const i2 = setInterval(fetchTokenPrice, 1000 * 60 * 5);
    const searchParams = new URLSearchParams(location.search);
    const param = searchParams.get("buy");
    console.log("buy", param);
    if (param == "true") {
      setWalletConnect(true);
    } else {
      setWalletConnect(false);
    }
    return () => {
      clearInterval(i1);
      clearInterval(i2);
    };
  }, [location, navigate]);

  const [showPopup, setShowPopup] = useState(false);
  const popupRef = useRef(null);
  useEffect(() => {
    // Event listener for clicks outside of the popup
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        onClose(); // Close the popup if clicked outside
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup event listener on unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  useEffect(() => {
    if (isOpen) {
      // Delay adding the "show" class to ensure smooth animation
      setTimeout(() => setShowPopup(true), 50);
    } else {
      setShowPopup(false);
    }
  }, [isOpen]);
  // useEffect(() => {
  //   processPendingTransactions();
  // }, []);

  const getBalance = async (address) => {
    const getBalResponse = await fetch(
      `${TON_EXPLORER}/getWalletInformation?address=${address}`,
      {
        method: "GET",
        headers: {
          "X-API-Key":
            "ec2cd059d5d4ec929eaa7936442b3938cce4792a6834faa229c2100060f37c67",
        },
      }
    );
    if (getBalResponse.ok) {
      const response = await getBalResponse.json();
      const { result } = response;
      const tonBalance = parseInt(result.balance) / 1e9;
      return tonBalance;
    } else {
      setBalance(0);
    }
  };

  useEffect(() => {
    if (activeWallet) {
      setUser((prev) => ({ ...prev, walletAddress: activeWalletAddress }));
      console.log(activeWalletAddress);
      getBalance(activeWalletAddress).then((bal) => {
        setBalance(bal);
        console.log(bal);
      });
    }
  }, [tonConnectUI]);

  function createTransactionObject(amount) {
    const transaction = {
      validUntil: Math.floor(Date.now() / 1000) + 600,
      messages: [
        {
          address: "UQDFPqaHQKK_uZuU6pHhAGbKV2eOHYxjpKyfY5PlmW0l6sqp", // for test
          // address: "UQChJhuS12aL-kyiqu4onDhFapXIQ4MdJINN23KQ18sQRjAa",
          // UQA-d6zF28paUMDjQlGcjqxZ7elhm-haDIt2NNpLx0FJSCnj
          amount, // amount * 1e9 -> to string
          payload: "",
        },
      ],
    };
    return transaction;
  }

  const goBack = () => {
    navigate("/?noload=true");
  };

  const getLatestTx = async (limit) => {
    if (userAddress !== "") {
      try {
        // api key = ec2cd059d5d4ec929eaa7936442b3938cce4792a6834faa229c2100060f37c67
        const txResponse1 = await fetch(
          `${TON_EXPLORER}/getTransactions?limit=10&to_lt=0&archival=false&address=${userAddress}`, // Fetch 10 transactions
          {
            method: "GET",
            headers: {
              "X-API-Key": TON_EXPLORER_KEY, // store in env
            },
          }
        );

        if (txResponse1.ok) {
          const tx = await txResponse1.json();

          if (limit === 1) {
            // Return only the first transaction (index 0)
            return {
              hash: tx?.result?.[0]?.transaction_id.hash,
              lt: tx?.result?.[0]?.transaction_id.lt,
            };
          } else if (limit === 10) {
            // Return the full list of transactions
            return tx.result.map((transaction) => ({
              hash: transaction?.transaction_id?.hash,
              lt: transaction?.transaction_id?.lt,
            }));
          }
        } else {
          console.error(
            "Failed to fetch transactions:",
            await txResponse1.text()
          );
          return null;
        }
      } catch (error) {
        console.error("Error fetching transactions:", error);
        return null;
      }
    } else {
      await tonConnectUI?.wallet?.connectWallet();
    }
  };

  const savePendingTransaction = (transaction) => {
    let pendingTxs =
      JSON.parse(localStorage.getItem("pendingTransactions")) || [];
    // check for duplication of hash
    const exists = pendingTxs.some((tx) => tx.hash === transaction.hash);

    if (!exists) {
      pendingTxs.push(transaction);
      localStorage.setItem("pendingTransactions", JSON.stringify(pendingTxs));
    } else {
      console.log("Transaction already exists in pending transactions.");
    }
  };

  const removePendingTransaction = (hash) => {
    let pendingTxs =
      JSON.parse(localStorage.getItem("pendingTransactions")) || [];
    pendingTxs = pendingTxs.filter((tx) => tx.hash !== hash);
    localStorage.setItem("pendingTransactions", JSON.stringify(pendingTxs));
  };

  const checkPendingTransactionStatus = async (pendingTx) => {
    try {
      const { hash, userAddress } = pendingTx;

      const latestTxs = await getLatestTx(10);

      if (latestTxs && latestTxs.length > 0) {
        const matchedTx = latestTxs.find((tx) => tx.hash === hash);

        if (matchedTx) {
          console.log(`Transaction ${hash} confirmed.`);
          return { confirmed: true, latestTx: matchedTx };
        } else {
          console.log(`Transaction ${hash} still pending or not found.`);
          return { confirmed: false };
        }
      } else {
        console.log("No transactions found or failed to fetch transactions.");
        return { confirmed: false };
      }
    } catch (error) {
      console.error("Error checking transaction status:", error);
      return { confirmed: false, error };
    }
  };

  //
  const processPendingTransactions = async () => {
    const pendingTxs =
      JSON.parse(localStorage.getItem("pendingTransactions")) || [];

    const userPendingTxs = pendingTxs.filter(
      (tx) => tx.userAddress === userAddress
    );

    if (userPendingTxs.length === 0) {
      console.log("No pending transactions for the connected user.");
      return;
    }

    for (const pendingTx of userPendingTxs) {
      const { confirmed, latestTx } = await checkPendingTransactionStatus(
        pendingTx
      );

      if (confirmed) {
        try {
          const resp = await fetch(
            `${SERVER_ENDPOINT}/v1/transaction/success`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${jwt}`,
              },
              body: JSON.stringify({
                packageID: pendingTx.packageID,
                latestTx,
                userAddress: pendingTx.userAddress,
                amount: pendingTx.amount,
              }),
            }
          );

          if (resp.status === 200) {
            const parsedRes = await resp.json();
            setShowConfetti(true);
            setLastTxnSuccess(pendingTx.packageID);

            if (pendingTx.userAddress === userAddress) {
              setUser((user) => ({ ...user, ...parsedRes.data }));
            }

            console.log("Transaction confirmed and backend updated.");
            removePendingTransaction(pendingTx.hash); // Remove from pending transactions
          } else {
            toast.error("Error updating package, please contact support.");
            console.error("Error updating backend:", await resp.text());
          }
        } catch (error) {
          toast.error("Error updating package, please contact support.");
          console.error("Error sending transaction to backend:", error);
        }
      } else {
        console.log(`Transaction ${pendingTx.hash} still pending.`);
      }
    }
  };

  useImperativeHandle(ref, () => ({
    processPendingTransactions,
  }));

  const handlePurchase = async (packageID, amount) => {
    const latestTx1 = await getLatestTx(1);
    console.log("Old tx: ", latestTx1);

    try {
      amount = parseFloat(amount.toFixed(9)) * 1e9;
      const resp = await tonConnectUI.sendTransaction(
        createTransactionObject(amount)
      );
      console.log("TX: ", resp);

      setShowConfirmModal(true);

      let latestTx2 = latestTx1;
      let counter = 0;
      const counterMax = 7;
      const pollingInterval = 5000;

      await sleep(pollingInterval);

      while (counter < counterMax) {
        latestTx2 = await getLatestTx(1);
        console.log("Polling latest tx: ", latestTx2);

        if (latestTx2 && latestTx2.hash !== latestTx1?.hash) {
          console.log("Transaction successfully received", latestTx2);
          break;
        }

        counter++;
        await sleep(pollingInterval);
      }

      if (latestTx2 && latestTx2.hash !== latestTx1?.hash) {
        try {
          const resp = await fetch(
            `${SERVER_ENDPOINT}/v1/transaction/success`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${jwt}`,
              },
              body: JSON.stringify({
                packageID,
                latestTx2,
                userAddress,
                amount,
              }),
            }
          );

          if (resp.status === 200) {
            const parsedRes = await resp.json();
            setCongratsPopup(true);
            setShowConfetti(true);
            setLastTxnSuccess(packageID);
            setUser((user) => ({ ...user, ...parsedRes.data }));
          } else {
            toast.error("Error updating package, please contact support.");
          }
        } catch (error) {
          toast.error("Error updating package, please contact support.");
          console.error("Backend error:", error);
        }
      } else {
        toast.error("Transaction could not be confirmed, storing as pending.");
        savePendingTransaction({
          packageID,
          latestTx: resp,
          userAddress,
          amount,
        });
      }

      setShowConfirmModal(false);
    } catch (error) {
      console.error("Failed to send transaction:", error);
      toast.error("Failed to send transaction: " + error.message);
      setShowConfirmModal(false);
    }
  };

  function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  const handleDisconnect = async () => {
    // if (!walletConnect) {
    //   alert("Wallet not connected");
    //   return;
    // }

    await handleActiveWalletDisconnect();
    // navigate("/?noload=true");
    onClose();
  };

  const handleWalletConnection = async () => {
    // console.log(await tonConnectUI?.connector);
  };

  return (
    <div className="popup-overlay">
      <div
        ref={popupRef}
        className={`popup-container ${showPopup ? "show" : ""}`}
        style={{
          backgroundColor: "#1D1C1D",
          borderTopLeftRadius: "60px",
          borderTopRightRadius: "60px",
          borderTop: "5px solid yellow",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            padding: "20px",
          }}
        >
          {!activeWallet ? (
            <div className="v3WalletContainer">
              <div
                style={{
                  display: "flex",
                  border: "3px solid #ccc08f",
                  backgroundColor: "#3e3e3e",
                  borderRadius: "20px",
                  width: "10rem",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: "2rem",
                }}
              >
                <img src={walletIcon} />
              </div>
              <div className="v3_WalletText">
                <span
                  className="v3_walletTitle"
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  POWERED BY TON
                  <img src={tonIcon} alt="" />
                </span>{" "}
                <br />
                <span className="v3WalletSubText">
                  Connect your Ton wallet to get maximized Airdrop and unlock
                  exclusive benefits
                </span>
              </div>

              <div style={{ display: "flex", flexDirection: "column", gap: "14px" }}>
                <button
                  style={{
                    fontSize: "15px",
                  }}
                  onClick={handleOkxConnect}
                  className="stripe-button"
                >
                  Connect Okx Wallet
                </button>
                <button
                  style={{
                    fontSize: "15px",
                  }}
                  className="stripe-button"
                  onClick={handleTonConnect}
                >
                  Connect Ton Wallet
                </button>
              </div>
            </div>
          ) : (
            <>
              <div
                style={{
                  display: "flex",
                  border: "3px solid #ccc08f",
                  backgroundColor: "#3e3e3e",
                  borderRadius: "20px",
                  width: "10rem",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: "2rem",
                }}
              >
                <img src={walletIcon} alt="" />
              </div>
              <div
                style={{
                  color: "white",
                  fontFamily: "roboto",
                  fontWeight: "800",
                  fontSize: "22px",
                  marginTop: "1rem",
                }}
              >
                Wallet Address
              </div>
              <div
                style={{
                  color: "#878073",
                  fontFamily: "roboto",
                  weight: "800",
                  fontSize: "18px",
                }}
              >
                {user?.walletAddress?.length > 10 // Check if the length is greater than 10
                  ? `${user.walletAddress.slice(
                    0,
                    4
                  )}...${user.walletAddress.slice(-4)}` // Display first 4 and last 4 characters
                  : user?.walletAddress}
              </div>

              <div
                style={{
                  display: "flex",
                  backgroundColor: "#222222",
                  borderRadius: "20px",
                  alignItems: "center",
                  marginTop: "1rem",
                  width: "100%",
                  height: "5rem",
                  boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
                }}
              >
                <img
                  src={tonIcon}
                  style={{ width: "5rem", height: "5rem", objectFit: "cover" }}
                  alt=""
                />
                <div
                  style={{
                    fontFamily: "roboto",
                    fontWeight: "600",
                    margin: "0 0 1rem 3rem",
                  }}
                >
                  <div style={{ color: "#78746D", fontSize: "22px" }}>
                    TON Balance
                  </div>
                  <div style={{ color: "white", fontSize: "16px" }}>
                    {balance?.toFixed(2)}
                  </div>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  backgroundColor: "#222222",
                  borderRadius: "20px",
                  alignItems: "center",
                  marginTop: "1rem",
                  width: "100%",
                  height: "5rem",
                  boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
                }}
              >
                <img
                  src={pepeIcon}
                  style={{
                    width: "3.5rem",
                    height: "3.5rem",
                    marginLeft: "0.9rem",
                    objectFit: "cover",
                  }}
                  alt=""
                />
                <div
                  style={{
                    fontFamily: "roboto",
                    fontWeight: "600",
                    margin: "15px 0 1rem 1rem",
                  }}
                >
                  <div style={{ color: "#78746D", fontSize: "22px" }}>
                    Pepe Credits Balance
                  </div>
                  <div style={{ color: "#c9c9c9", fontSize: "16px" }}>
                    {formatNumberWithCommas(user.pepeCredits.toFixed(2))}
                  </div>
                  <div
                    onClick={() => navigate("/privatesale")}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      textAlign: "right",
                      justifyContent: "end",
                      marginRight: "-18px",
                    }}
                  >
                    <span
                      style={{
                        color: "#c9c9c9",
                        fontSize: "12px",
                        textAlign: "right",
                      }}
                    >
                      Purchase Now
                    </span>
                    <img
                      src={v3_puchaseArrow}
                      alt=""
                      style={{
                        height: "30px",
                        width: "30px",
                      }}
                    />
                  </div>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  backgroundColor: "#222222",
                  borderRadius: "20px",
                  alignItems: "center",
                  marginTop: "1rem",
                  width: "100%",
                  height: "5rem",
                  boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
                }}
              >
                <div
                  style={{
                    color: "#78746D",
                    fontSize: "18px",
                    textAlign: "center",
                    margin: "0px auto",
                    width: "100%",
                  }}
                >
                  SWAP ( COMING SOON )
                </div>
              </div>
              <div onClick={handleDisconnect} style={{ marginTop: "15px" }}>
                <button className="stripe-button">
                  <a href="" style={{ textDecoration: "none", color: "white" }}>
                    Disconnect Wallet
                  </a>
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
});

export default Wallet;
