import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import solanaIcon from "../../../../media/v3_solanaLp.png";
import tonIcon from "../../../../media/V3_Ton.png";
import TonComponent from "../../../component/common/TonComponent";
import "./popAdd.css";
const PopAddModal = () => {
  const [tokenPrice, setTokenPrice] = useState(0.0);
  const navigate = useNavigate();
  return (
    <div className="PopAddContainer">
      <div
        style={{
          margin: "25px auto",
        }}
      >
        <span
          style={{
            color: "#EADE00",
            fontSize: "25px",
            fontWeight: 600,
            letterSpacing: "1px",
            fontFamily: "Roboto",
          }}
        >
          JOIN PEPE’S ARMY
        </span>
        <br />
        <span
          style={{
            fontSize: "16px",
            color: "white",
            marginTop: "3px",
            fontFamily: "Roboto",
          }}
        >
          the next 1000x meme
        </span>{" "}
        <br />
        <div style={{ marginTop: "35px" }}>
          <p
            style={{
              fontSize: "18px",
              fontWeight: 600,
              margin: "0px auto",
              fontFamily: "Roboto",
            }}
          >
            TODAY’S PRICE:
          </p>
          <span
            style={{
              fontSize: "30px",
              fontWeight: 700,
              margin: "10px auto",
            }}
          >
            ${tokenPrice.toFixed(10)}
          </span>
          <br />
          <span
            style={{
              fontSize: "15px",
              fontWeight: 500,
              color: "#43FF30",
            }}
          >
            (launching price $0.002)
          </span>
        </div>
      </div>
      <div>
        <TonComponent setTokenPrice={setTokenPrice} />
      </div>
      <div>
        <button
          onClick={() => navigate("/privatesale")}
          style={{
            background: "#009E22B5",
            color: "white",
            marginTop: "15px",
            outline: "none",
            textAlign: "center",
            fontWeight: 800,
            border: "none",
            padding: "5px 10px",
            borderRadius: "12px",
            width: "78%",
            height: "50px",
            fontSize: "20px",
            fontFamily: "Roboto",
          }}
        >
          JOIN NOW
        </button>
        <div
          style={{
            marginTop: "15px",
            display: "flex",
            textAlign: "center",
          }}
        >
          <span
            style={{
              color: "#C9C9C9",
              fontSize: "12px",
              textTransform: "uppercase",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
              width: "100%",
            }}
          >
            POWERED BY TON
            <img
              src={tonIcon}
              alt=""
              style={{
                width: "60px",
                height: "60px",
              }}
            />
            AND SOLANA
            <img
              src={solanaIcon}
              alt=""
              style={{
                width: "60px",
                height: "60px",
              }}
            />
          </span>
        </div>
      </div>
    </div>
  );
};

export default PopAddModal;
