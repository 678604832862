import React, { useEffect, useRef, useState } from "react";

const Faq = ({ isOpen, onClose }) => {
  // const [visible, setVisible] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const popupRef = useRef(null);
  useEffect(() => {
    if (isOpen) {
      // Delay adding the "show" class to ensure smooth animation
      setTimeout(() => setShowPopup(true), 50);
    } else {
      setShowPopup(false);
    }
  }, [isOpen]);

  useEffect(() => {
    // Event listener for clicks outside of the popup
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        onClose(); // Close the popup if clicked outside
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup event listener on unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);
  // useEffect(() => {
  //   setVisible(true);
  // }, []);

  return (
    <div className="popup-overlay">
      <div
        ref={popupRef}
        className={`popup-container ${showPopup ? "show" : ""}`}
        style={{
          backgroundColor: "#1D1C1D",
          borderTopLeftRadius: "60px",
          borderTopRightRadius: "60px",
          borderTop: "5px solid yellow",
        }}
      >
        <div
          style={{
            padding: "20px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                color: "#c9c9c9",
                fontSize: "35px",
                margin: "1",
                fontWeight: "800",
                fontFamily: "Roboto",
              }}
            >
              Q&A
            </div>
            <span
              style={{
                fontSize: "16px",
                marginBottom: "2rem",
                color: "#c9c9c9",
                fontWeight: "500",
                fontFamily: "Roboto",
                marginTop: "1rem",
              }}
            >
              Welcome to the Pepe's FrogBar private sale!
            </span>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              marginBottom: "1rem",
            }}
          >
            <div
              style={{
                color: "#c9c9c9",
                fontSize: "20px",
                fontWeight: "800",
                marginBottom: "5px",
              }}
            >
              Participate in one of the most exciting crypto launches of 2024
              and secure your allocation in this innovative project
            </div>
            <div
              style={{ color: "#c9c9c9", fontSize: "18px", textAlign: "left" }}
            >
              Pepe's frogbar is first of its kind story telling and
              narrative-driven Private Sale.
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              marginBottom: "1rem",
              // justifyContent: "flex-start"
            }}
          >
            <div
              style={{ color: "#c9c9c9", fontSize: "18px", textAlign: "left" }}
            >
              Two Pathways to Participate:
              <ol>
                <li>
                  Earn Through Engagement: Actively participate in our Telegram
                  game to farm Tap Credits. These credits directly contribute to
                  your allocation in the private sale. A portion of the total
                  token supply (5%) is reserved for airdrops to dedicated
                  community members.
                </li>
                <li>
                  Direct Purchase: Buy directly into the private sale through
                  our exclusive packages. Each package grants you Pepe Credits,
                  which represent your allocation. The higher the package, the
                  larger your holdings in the project. A total token supply (
                  46%) is reserved to dedicated Premium holders.
                </li>
              </ol>
              <div>
                Exclusive Benefits:
                <ul>
                  <li>
                    Private Sale Participation: Gain early access to the Pepe's
                    FrogBar token at a potentially discounted price.
                  </li>
                  <li>
                    Token Allocation: Earn Pepe Credits, which can be redeemed
                    for tokens upon the official launch.
                  </li>
                  <li>
                    In-Game Perks: Enjoy exclusive features and rewards within
                    our engaging Telegram game.
                  </li>
                </ul>
              </div>
              <div>
                Investment Highlights:
                <ul>
                  <li>
                    Fair Distribution: Our tokenomics prioritize community
                    engagement and fair allocation.
                  </li>
                  <li>
                    Growth Potential: Benefit from the potential for significant
                    returns as the project gains traction.
                  </li>
                  <li>
                    Strategic Launch: The planned Q4 2024 token launch aligns
                    with favorable market conditions.
                  </li>
                </ul>
              </div>
            </div>
            <div
              style={{
                color: "#c9c9c9",
                fontSize: "18px",
                textAlign: "left",
                marginTop: "15px",
              }}
            >
              Tokenomic Distribution:
              <ul>
                <li>46% Private Sale ( Premium Members )</li>
                <li>5% Game Aidrop ( Aidrop to Game Users )</li>
                <li>30% Staking ( Staking Program )</li>
                <li>15% Listing ( Listing DEX and CEX )</li>
                <li>2% Advisory ( Official Advisors )</li>
                <li>2% Devs (Founding Team )</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Faq;
