import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import DailyBooster from "./DailyBooster";
import MultiTap from "./MultiTap";

import arrow from "../../../media/V3_arrow.png";
import batt from "../../../media/V3_battery.png";

const Booster = ({ isOpen, onClose }) => {
  const [showPopup, setShowPopup] = useState(false);
  const popupRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    // Event listener for clicks outside of the popup
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        onClose(); // Close the popup if clicked outside
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup event listener on unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  useEffect(() => {
    if (isOpen) {
      // Delay adding the "show" class to ensure smooth animation
      setTimeout(() => setShowPopup(true), 50);
    } else {
      setShowPopup(false);
    }
  }, [isOpen]);

  return (
    <div className="popup-overlay">
      <div
        ref={popupRef}
        className={`popup-container ${showPopup ? "show" : ""}`}
        style={{
          backgroundColor: "#1d1c1d",
          borderTopLeftRadius: "60px",
          borderTopRightRadius: "60px",
          borderTop: "5px solid yellow",
          zIndex: 10,
          position: "relative",
        }}
      >
        <div
          style={{
            padding: "25px",
          }}
        >
          <div
            style={{
              fontSize: "1.8rem",
              marginTop: "1rem",
              color: "white",
              fontFamily: "roboto",
              fontWeight: "800",
            }}
          >
            Daily BOOSTER
          </div>
          <DailyBooster />
          <MultiTap />

          <div
            style={{
              display: "flex",
              alignItems: "center",
              backgroundColor: "#333333",
              height: "6rem",
              borderRadius: "20px",
              marginTop: "1rem",
            }}
          >
            <img
              src={batt}
              alt="batt"
              style={{ marginTop: "1.2rem", marginLeft: "-10px" }}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: "0.2rem",
              }}
            >
              <div
                style={{
                  fontSize: "1.5rem",
                  color: "white",
                  fontFamily: "roboto",
                  fontWeight: "400",
                }}
              >
                Boost Energy
              </div>
              <div
                style={{
                  fontSize: "1rem",
                  color: "#575757",
                  fontFamily: "roboto",
                  fontWeight: "400",
                }}
              >
                Boost PERMANENTLY
              </div>
              <div
                style={{
                  fontSize: "1rem",
                  color: "#575757",
                  fontFamily: "roboto",
                  fontWeight: "400",
                }}
              >
                Energy per Hour
              </div>
            </div>

            <img
              src={arrow}
              alt="arrow"
              style={{ marginLeft: "3rem" }}
              onClick={() => navigate("/friend?bonus=true")}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Booster;
