import React from "react";
import v3_borderImg from "../../../media/v3_borderImg.png";

const ComingSoon = () => {
  return (
    <div
      style={{
        width: "98%",
        // height: "40%",
        marginTop: "20px",
        position: "relative",
      }}
    >
      <img src={v3_borderImg} alt="" width="100%" height="100%" />
      <div
        style={{
          position: "absolute",
          zIndex: 1,
          top: "35%",
          left: "30%",
          background: "#606060",
          color: "#C9C9C9",
          padding: "3px 20px",
          borderRadius: "10px",
          fontWeight: "bolder",
          textAlign: "center",
        }}
      >
        <p>Coming Soon</p>
      </div>
    </div>
  );
};

export default ComingSoon;
