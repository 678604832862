import { animated, useSpring } from "@react-spring/web";
import { useTonAddress } from "@tonconnect/ui-react";
import React, { useContext, useEffect, useState } from "react";
import { FaStar } from "react-icons/fa6";
import { MdDoubleArrow } from "react-icons/md";
import { UserContext } from "../../context/UserContext";
import locked from "../../media/clubLocked.gif";
import ProfileBar from "../Shared/ProfileBar/ProfileBar";
import "./club.css";

import moment from "moment/moment";
import { Watch } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import Premium from "../../app/component/common/Premium.jsx";
import { AuthContext } from "../../context/AuthContext";
import { ClubContext } from "../../context/ClubContext";
import { HotelContext } from "../../context/HotelContext";
import { MotorContext } from "../../context/MotorContext";
import sports from "../../media/clubs & motors/dealer_comfort.png";
import Luxury from "../../media/clubs & motors/dealer_luxury.png";
import v3motorBikeImg from "../../media/clubs & motors/dealer_motorcycle.png";
import Jets from "../../media/clubs & motors/dealer_private_jets.png";
import v3Suvis from "../../media/clubs & motors/dealer_suvs.png";
import Yacht from "../../media/clubs & motors/dealer_yatch_club.png";
import rio from "../../media/clubs & motors/frogbar_RIO.png";
import beijin from "../../media/clubs & motors/frogbar_beijing2.png";
import dubai from "../../media/clubs & motors/frogbar_dubai.png";
import istanbul from "../../media/clubs & motors/frogbar_istanbul.png";
import london from "../../media/clubs & motors/frogbar_london.png";
import moscow from "../../media/clubs & motors/frogbar_moscow.png";
import mumbai from "../../media/clubs & motors/frogbar_mumbai.png";
import paris from "../../media/clubs & motors/frogbar_paris.png";
import roma from "../../media/clubs & motors/frogbar_roma.png";
import singapore from "../../media/clubs & motors/frogbar_singapore.png";
// hotels
//  china: china,
// greece: greece,
// thailand: thailand,
// holland: holland,
// hawaii: hawaii,
// nigeria: nigeria,
// egypt: egypt,
// austria: austria,
// spain: spain,
// usa: usa,
import austria from "../../media/hotels_img/hotel_austria.png";
import china from "../../media/hotels_img/hotel_china.png";
import egypt from "../../media/hotels_img/hotel_egypt.png";
import greece from "../../media/hotels_img/hotel_greece.png";
import hawaii from "../../media/hotels_img/hotel_hawai.png";
import holland from "../../media/hotels_img/hotel_holland.png";
import nigeria from "../../media/hotels_img/hotel_nigeria.png";
import spain from "../../media/hotels_img/hotel_spain.png";
import thailand from "../../media/hotels_img/hotel_thailand.png";
import usa from "../../media/hotels_img/hotel_usa.png";
import {
  default as coin,
  default as creditCoinGif,
} from "../../media/silver-pepe-bg.png";
import tick from "../../media/tick.png";
import {
  clubLevelDetails,
  hotelIdxToNameMapping,
  hotelLevelDetails,
  motorIdxToNameMapping,
  motorLevelDetails,
  SERVER_ENDPOINT,
  userClubRankToStarsMapping,
  userClubToRankNameMapping,
} from "../../utils/constants";
import { formatNumberWithCommas } from "../../utils/formatedNumberWithComas.js";
import { formatMinutes } from "../../utils/helpers.js";
import BottomButtons from "../Shared/BottomNavigation/bottomButtons";
import SettingsPopup from "../Shared/ProfileBar/SettingsPopup.jsx";
import V3Popup from "../Shared/v3Popup/V3Popup";
import LevelUp from "./LevelUp";
import LevelUpHotel from "./LevelUpHotel";
import LevelUpMotor from "./LevelUpMotor";
import useTelegramBackButton from "../../utils/backButton.js";

const imageMapHotelnew = {
  china: china,
  greece: greece,
  thailand: thailand,
  holland: holland,
  hawaii: hawaii,
  nigeria: nigeria,
  egypt: egypt,
  austria: austria,
  spain: spain,
  usa: usa,
};

const imageMap = {
  dubai: dubai,
  beijing: beijin,
  mumbai: mumbai,
  istanbul: istanbul,
  paris: paris,
  london: london,
  rome: roma,
  rio: rio,
  moscow: moscow,
  singapore: singapore,
  la: moscow,
  miami: roma,
  motorbike: v3motorBikeImg,
  suvs: v3Suvis,
  sports: sports,
  luxury: Luxury,
  jets: Jets,
  yacht: Yacht,
};

const Club = () => {
  const [visible] = useState(false);
  const {
    clubCredits,
    userClubs,
    setUserClubs,
    userStars,
    userClubRank,
    fetchUserClub,
  } = useContext(ClubContext);
  const { userMotors, setUserMotors, motorCredits } = useContext(MotorContext);
  const { userHotels, setUserHotels, hotelCredits } = useContext(HotelContext);
    
  useTelegramBackButton();  
  const connectedAddress = useTonAddress();
  const {
    userCredits,
    setUserCredits,
    user,
    showConnectWallet,
    setShowConnectWallet,
  } = useContext(UserContext);
  const [category, setCategory] = useState("clubs");
  const [availability, setAvailability] = useState(true);
  const [barWidth, setBarWidth] = useState(0);
  const [selectedClub, setSelectedClub] = useState(-1);
  const [selectedClubLevel, setSelectedClubLevel] = useState(-1);
  const [selectedMotor, setSelectedMotor] = useState(-1);
  const [selectedMotorLevel, setSelectedMotorLevel] = useState(-1);
  const [selectedHotel, setSelectedHotel] = useState(-1);
  const [selectedHotelLevel, setSelectedHotelLevel] = useState(-1);
  const [isSettings, setIsSettings] = useState(false);
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);
  const { jwt } = useContext(AuthContext);
  const navigate = useNavigate();

  const topAnimation = useSpring({
    from: { transform: "translateY(-100%)", opacity: 0 },
    to: async (next, cancel) => {
      await next({ opacity: 1 });
      await next({
        transform: visible ? "translateY(0%)" : "translateY(90%)",
      });
    },
    config: { duration: 300 },
  });

  const handlePopupClose = () => {
    setSelectedClub(-1);
  };

  async function fetchUserMotor() {
    try {
      const resp = await fetch(`${SERVER_ENDPOINT}/v1/motor/details`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwt}`,
        },
      });

      if (resp.status === 200) {
        const parsedRes = await resp.json();
        const motors = parsedRes.motorLevel.map((level, idx) => {
          return {
            motorId: idx,
            motorName: motorIdxToNameMapping[idx],
            isOnUpgrade: parsedRes.isOnUpgrade[idx],
            isUnlocked: parsedRes.isUnLocked[idx],
            upgradeStartAt: parsedRes.timeOfStart[idx],
            motorLevel: level,
          };
        });

        console.log("this is moter", motors);
        setUserMotors(motors);
        setUserCredits(
          (credits) => credits + (parsedRes?.creditsRewarded || 0)
        );
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function fetchUserHotel() {
    try {
      const resp = await fetch(`${SERVER_ENDPOINT}/v1/hotel/hotel-details`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwt}`,
        },
      });

      if (resp.status === 200) {
        const parsedRes = await resp.json();
        console.log("Hotel Count -------------------", parsedRes)

        const hotels = parsedRes.hotelLevel.map((level, idx) => ({
          hotelId: idx,
          hotelName: hotelIdxToNameMapping[idx] || `Hotel ${idx}`,
          isOnUpgrade: parsedRes.isOnUpgrade?.[idx] || false,
          isUnlocked: parsedRes.isUnLocked?.[idx] || false,
          upgradeStartAt: parsedRes.timeOfStart?.[idx] || null,
          hotelLevel: level,
        }));

        console.log("Hotel details:", hotels);
        setUserHotels(hotels);
        setUserCredits((credits) => credits + (parsedRes?.creditsRewarded || 0));
      } else {
        console.error("Failed to fetch hotel details:", resp.statusText);
      }
    } catch (error) {
      console.error("Error fetching hotel details:", error);
    }
  }
  useEffect(() => {
    const finalWidth =
      (userStars * 100) / userClubRankToStarsMapping[userClubRank + 1]; // Calculate the width
    setTimeout(() => {
      setBarWidth(finalWidth); // Set the final width after a small delay
    }, 100); // Small delay before starting the animation
  }, [userStars, userClubRank]);

  useEffect(() => {
    fetchUserClub();
    fetchUserMotor();
    fetchUserHotel();
  }, []);

  const handleSettingClose = () => {
    setIsSettings(false);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        background: "#1D1C1D",
      }}
    >
      <div className="taskContent">
        <animated.div style={{ ...topAnimation }}>
          <div className="pfBarr2">
            <ProfileBar
              showConnectWallet={showConnectWallet}
              setShowConnectWallet={setShowConnectWallet}
              connectedAddress={connectedAddress}
              setIsSettings={setIsSettings}
            />
            <div className="task_AmountSection">
              <img src={creditCoinGif} alt="" height="60px" width="60px" />
              <span className="task_amount">
                {formatNumberWithCommas(
                  userCredits +
                  Math.floor(clubCredits) +
                  Math.floor(motorCredits) +
                  Math.floor(hotelCredits)
                )}
              </span>
            </div>
          </div>
        </animated.div>
      </div>
      <div
        className="CeoRankDiv"
        style={{ margin: "12rem 0 0 0", width: "90%" }}
      >
        <div className="ceoRankInfo">
          <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
            <FaStar color="#FFAE42" fontSize="25px" />
            <p
              style={{
                fontSize: "15px",
                margin: "0px",
                fontWeight: "bolder",
              }}
            >
              {userStars}/{userClubRankToStarsMapping[userClubRank + 1]}
            </p>
          </div>
          <div className="Playlevelscore">
            <div
              className="PlayprogressBox playProgressClub"
              style={{
                width: "90%",
                height: "0.5rem",
              }}
            >
              <div
                className="PlaygreenBar playGreenClub"
                style={{
                  width: `${barWidth}%`,
                  height: "0.5rem",
                }}
              ></div>
            </div>
          </div>
          <div
            style={{ display: "flex", alignItems: "center", gap: "5px" }}
            onClick={() => {
              navigate("/ceo");
            }}
          >
            <p
              style={{
                fontSize: "15px",
                margin: "0px",
                fontWeight: "bold",
              }}
            >
              {userClubToRankNameMapping[userClubRank]}
            </p>
            <MdDoubleArrow color="white" fontSize="25px" />
          </div>
        </div>
      </div>
      <div className="button-group">
        <button
          onClick={() => setCategory("clubs")}
          className={category === "clubs" ? "buttons active" : "buttons"}
        >
          CLUBS
        </button>
        <button
          onClick={() => setCategory("motors")}
          className={category === "motors" ? "buttons active" : "buttons"}
        >
          {/* <div te> */}
          MOTORS
          <img src={tick} alt={tick} style={{ marginTop: "0rem" }} />
          {/* </div> */}
        </button>
        <button
          // disabled={true}
          onClick={() => setCategory("hotels")}
          className={category === "hotels" ? "buttons active" : "buttons"}
        >
          HOTELS
          <img src={tick} alt={tick} style={{ marginTop: "0rem" }} />
        </button>
        {/* <button
          onClick={() => setCategory("hotels")}
          disabled
          className={category === "hotels" ? "buttons active" : "buttons"}
        >
          <span style={{ marginRight: "-10px" }}>HOTELS</span>
          <img src={commingSoon} alt={commingSoon} height="70px" width="70px" />
        </button> */}
      </div>
      <div className="club-container">
        <div style={{ display: "flex" }}>
          <button
            onClick={() => setAvailability(true)}
            className={availability ? "buttons2 active2" : "buttons2"}
          >
            Available
          </button>
          <button
            onClick={() => setAvailability(false)}
            className={!availability ? "buttons2 active2" : "buttons2"}
          >
            Unavailable
          </button>
        </div>

        <div className="card-container">
          {category === "motors" && user?.isPremiumUser
            ? userMotors.map(
              (
                {
                  isOnUpgrade,
                  isUnlocked,
                  upgradeStartAt,
                  motorLevel,
                  motorName,
                },
                idx
              ) => {
                if (
                  (availability && !isUnlocked) ||
                  (!availability && isUnlocked)
                ) {
                  return;
                }
                const minutes = isOnUpgrade
                  ? moment
                    .utc(upgradeStartAt)
                    .add(motorLevelDetails[motorLevel].duration, "minutes")
                    .diff(moment().utc(), "minutes")
                  : 0;
                return (
                  <button
                    disabled={!availability}
                    onClick={() => {
                      if (isOnUpgrade && minutes < 0) fetchUserMotor();

                      if (!isOnUpgrade) {
                        setSelectedMotor(idx);
                        setSelectedMotorLevel(motorLevel);
                      }
                    }}
                    style={{ border: "none", backdropFilter: "blur(10px)" }}
                    className="card"
                    key={idx}
                  >
                    {!isUnlocked && (
                      <>
                        <div
                          style={{
                            position: "absolute",
                            zIndex: 1,
                          }}
                        >
                          <img
                            src={locked}
                            alt=""
                            height="160px"
                            width="160px"
                          />
                        </div>
                        <div
                          style={{
                            position: "absolute",
                            height: "100%",
                            width: "100%",
                            background: "#040404c2",
                          }}
                        ></div>
                      </>
                    )}
                    <div
                      style={{
                        fontFamily: "Roboto",
                        fontWeight: "500",
                        color: "white",
                        marginTop: "0.5rem",
                      }}
                    >
                      {motorName}
                    </div>
                    {isOnUpgrade && (
                      <div className="club-cover-div">
                        <Watch
                          visible={true}
                          height="80"
                          width="80"
                          radius="48"
                          color="#fff"
                          ariaLabel="watch-loading"
                          wrapperStyle={{ margin: "0 auto" }}
                        />
                      </div>
                    )}
                    <img
                      src={imageMap[motorName.toLowerCase()]} // change for motor image mapping
                      alt={`for ${motorName}`}
                      style={{
                        // height: "5rem",
                        width: "100%",
                      }}
                    />
                    <div
                      style={{
                        display: "flex",
                        fontWeight: "500",
                        color: "#C9C9C9",
                        fontFamily: "Roboto",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <div>Lvl {motorLevel}</div>
                      <div style={{ flexGrow: 1 }} />
                      <div>
                        ⏳
                        {formatMinutes(
                          motorLevelDetails[motorLevel].duration
                        )}{" "}
                      </div>
                    </div>
                    <hr style={{ width: "8rem" }} />
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        fontWeight: "500",
                        color: "#C9C9C9",
                        fontFamily: "Roboto",
                      }}
                    >
                      {isOnUpgrade ? (
                        minutes < 0 ? (
                          <div
                            style={{
                              borderRadius: "10px",
                              backgroundColor: "goldenrod",
                              color: "white",
                              fontSize: 12,
                              padding: "5px 15px",
                              zIndex: 10,
                              position: "relative",
                            }}
                          >
                            Ready
                          </div>
                        ) : (
                          `${formatMinutes(minutes)} left`
                        )
                      ) : (
                        <img src={coin} alt="coin" width={"20px"} />
                      )}
                      {!isOnUpgrade && motorLevelDetails[motorLevel].invest}
                    </div>
                  </button>
                );
              }
            )
            : category === "motors" && <Premium />}

          {/* ===============================Club=========== */}
          {category === "clubs" &&
            userClubs.map(
              (
                {
                  isOnUpgrade,
                  isUnlocked,
                  upgradeStartAt,
                  clubLevel,
                  clubName,
                },
                idx
              ) => {
                if (
                  (availability && !isUnlocked) ||
                  (!availability && isUnlocked)
                ) {
                  return;
                }
                const minutes = isOnUpgrade
                  ? moment
                    .utc(upgradeStartAt)
                    .add(clubLevelDetails[clubLevel].duration, "minutes")
                    .diff(moment().utc(), "minutes")
                  : 0;
                return (
                  <button
                    disabled={!availability || isOnUpgrade}
                    onClick={() => {
                      if (isOnUpgrade && minutes < 0) fetchUserClub();

                      if (!isOnUpgrade) {
                        setSelectedClub(idx);
                        setSelectedClubLevel(clubLevel);
                      }
                    }}
                    style={{ border: "none" }}
                    className="card"
                    key={idx}
                  >
                    {!isUnlocked && (
                      <>
                        <div
                          style={{
                            position: "absolute",
                            zIndex: 1,
                          }}
                        >
                          <img
                            src={locked}
                            alt=""
                            height="160px"
                            width="160px"
                          />
                        </div>
                        <div
                          style={{
                            position: "absolute",
                            height: "100%",
                            width: "100%",
                            background: "#040404c2",
                          }}
                        ></div>
                      </>
                    )}
                    <div
                      style={{
                        fontFamily: "Roboto",
                        fontWeight: "500",
                        color: "white",
                        marginTop: "0.5rem",
                      }}
                    >
                      {clubName}
                    </div>
                    {isOnUpgrade && (
                      <div className="club-cover-div">
                        <Watch
                          visible={true}
                          height="80"
                          width="80"
                          radius="48"
                          color="#fff"
                          ariaLabel="watch-loading"
                          wrapperStyle={{ margin: "0 auto" }}
                        />
                      </div>
                    )}
                    <img
                      src={imageMap[clubName.toLowerCase()]}
                      alt={`for ${clubName}`}
                      style={{
                        // height: "5rem",
                        width: "100%",
                      }}
                    />
                    <div
                      style={{
                        display: "flex",
                        fontWeight: "500",
                        color: "#C9C9C9",
                        fontFamily: "Roboto",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <div>Lvl {clubLevel + 1}</div>
                      <div style={{ flexGrow: 1 }} />
                      <div>
                        ⏳{formatMinutes(clubLevelDetails[clubLevel].duration)}{" "}
                      </div>
                    </div>
                    <hr style={{ width: "8rem" }} />
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        fontWeight: "500",
                        color: "#C9C9C9",
                        fontFamily: "Roboto",
                      }}
                    >
                      {isOnUpgrade ? (
                        minutes < 0 ? (
                          <div
                            style={{
                              borderRadius: "10px",
                              backgroundColor: "goldenrod",
                              color: "white",
                              fontSize: 12,
                              padding: "5px 15px",
                              zIndex: 10,
                              position: "relative",
                            }}
                          >
                            Ready
                          </div>
                        ) : (
                          `${formatMinutes(minutes)} left`
                        )
                      ) : (
                        <img src={coin} alt="coin" width={"20px"} />
                      )}
                      {!isOnUpgrade && clubLevelDetails[clubLevel].invest}
                    </div>
                  </button>
                );
              }
            )}

          {category === "hotels" && user?.isPremiumUser
            ? userHotels.map(
              (
                {
                  isOnUpgrade,
                  isUnlocked,
                  upgradeStartAt,
                  hotelLevel,
                  hotelName,
                },
                idx
              ) => {
                if (
                  (availability && !isUnlocked) ||
                  (!availability && isUnlocked)
                ) {
                  return;
                }
                const minutes = isOnUpgrade
                  ? moment
                    .utc(upgradeStartAt)
                    .add(hotelLevelDetails[hotelLevel].duration, "minutes")
                    .diff(moment().utc(), "minutes")
                  : 0;
                return (
                  <button
                    disabled={!availability}
                    onClick={() => {
                      if (isOnUpgrade && minutes < 0) fetchUserHotel();

                      if (!isOnUpgrade) {
                        setSelectedHotel(idx);
                        setSelectedHotelLevel(hotelLevel);
                      }
                    }}
                    style={{ border: "none", backdropFilter: "blur(10px)" }}
                    className="card"
                    key={idx}
                  >
                    {!isUnlocked && (
                      <>
                        <div
                          style={{
                            position: "absolute",
                            zIndex: 1,
                          }}
                        >
                          <img
                            src={locked}
                            alt=""
                            height="160px"
                            width="160px"
                          />
                        </div>
                        <div
                          style={{
                            position: "absolute",
                            height: "100%",
                            width: "100%",
                            background: "#040404c2",
                          }}
                        ></div>
                      </>
                    )}
                    <div
                      style={{
                        fontFamily: "Roboto",
                        fontWeight: "500",
                        color: "white",
                        marginTop: "0.5rem",
                      }}
                    >
                      {hotelName}
                    </div>
                    {isOnUpgrade && (
                      <div className="club-cover-div">
                        <Watch
                          visible={true}
                          height="80"
                          width="80"
                          radius="48"
                          color="#fff"
                          ariaLabel="watch-loading"
                          wrapperStyle={{ margin: "0 auto" }}
                        />
                      </div>
                    )}
                    <img
                      src={imageMapHotelnew[hotelName.toLowerCase()]}
                      alt={`for ${hotelName}`}
                      style={{
                        width: "100%",
                      }}
                    />
                    <div
                      style={{
                        display: "flex",
                        fontWeight: "500",
                        color: "#C9C9C9",
                        fontFamily: "Roboto",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <div>Lvl {hotelLevel}</div>
                      <div style={{ flexGrow: 1 }} />
                      <div>
                        ⏳
                        {formatMinutes(
                          hotelLevelDetails[hotelLevel].duration
                        )}{" "}
                      </div>
                    </div>
                    <hr style={{ width: "8rem" }} />
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        fontWeight: "500",
                        color: "#C9C9C9",
                        fontFamily: "Roboto",
                      }}
                    >
                      {isOnUpgrade ? (
                        minutes < 0 ? (
                          <div
                            style={{
                              borderRadius: "10px",
                              backgroundColor: "goldenrod",
                              color: "white",
                              fontSize: 12,
                              padding: "5px 15px",
                              zIndex: 10,
                              position: "relative",
                            }}
                          >
                            Ready
                          </div>
                        ) : (
                          `${formatMinutes(minutes)} left`
                        )
                      ) : (
                        <img src={coin} alt="coin" width={"20px"} />
                      )}
                      {!isOnUpgrade && hotelLevelDetails[hotelLevel].invest}
                    </div>
                  </button>
                );
              }
            )
            : category === "hotels" && <Premium />}
        </div>
      </div>

      <BottomButtons />
      <V3Popup isOpen={selectedClub !== -1} onClose={handlePopupClose}>
        <LevelUp
          userCredits={userCredits}
          setUserCredits={setUserCredits}
          setUserClubs={setUserClubs}
          selectedClub={userClubs[selectedClub]}
          selectedClubLevel={selectedClubLevel}
          handlePopupClose={handlePopupClose}
        />
      </V3Popup>
      <V3Popup
        isOpen={selectedMotor !== -1}
        onClose={() => setSelectedMotor(-1)}
      >
        <LevelUpMotor
          userCredits={userCredits}
          setUserCredits={setUserCredits}
          setUserMotors={setUserMotors}
          selectedMotor={userMotors[selectedMotor]}
          selectedMotorLevel={selectedMotorLevel}
          handlePopupClose={() => setSelectedMotor(-1)}
        />
      </V3Popup>

      <V3Popup
        isOpen={selectedHotel !== -1}
        onClose={() => setSelectedHotel(-1)}
      >
        <LevelUpHotel
          userCredits={userCredits}
          setUserCredits={setUserCredits}
          setUserHotels={setUserHotels}
          selectedHotel={userHotels[selectedHotel]}
          selectedHotelLevel={selectedHotelLevel}
          handlePopupClose={() => setSelectedHotel(-1)}
        />
      </V3Popup>
      {isSettings && (
        <SettingsPopup isOpen={isSettings} onClose={handleSettingClose} />
      )}
    </div>
  );
};

export default Club;
