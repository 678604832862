import { useEffect, useState, useRef, useCallback } from 'react';
import { WEBSOCKET_ENDPOINT } from '../utils/constants';

const useWebSocket = (options = {}) => {
    const [socket, setSocket] = useState(null);
    const [isConnected, setIsConnected] = useState(false);
    const messageQueue = useRef([]);

    const {
        onOpen,
        onClose,
        onMessage,
        onError,
        reconnect = true,
        reconnectInterval = 1000,
        maxReconnectAttempts = 10,
    } = options;

    const reconnectAttempts = useRef(0);

    const connect = useCallback(() => {
        const ws = new WebSocket(WEBSOCKET_ENDPOINT);

        ws.onopen = (event) => {
            setIsConnected(true);
            console.log("socket connected")
            reconnectAttempts.current = 0;
            if (onOpen) onOpen(event);
            // Send any messages that were queued while disconnected
            while (messageQueue.current.length > 0) {
                ws.send(messageQueue.current.shift());
            }
        };

        ws.onmessage = (event) => {
            if (onMessage) onMessage(event);
        };

        ws.onerror = (event) => {
            if (onError) onError(event);
        };


        ws.onclose = (event) => {
            setIsConnected(false);
            if (onClose) onClose(event);

            if (reconnect && reconnectAttempts.current < maxReconnectAttempts) {
                reconnectAttempts.current += 1;
                setTimeout(connect, reconnectInterval);
            }
        };

        setSocket(ws);
    }, [WEBSOCKET_ENDPOINT, reconnect, reconnectInterval, maxReconnectAttempts]);

    useEffect(() => {
        connect();

        return () => {
            if (socket) {
                socket.close();
            }
        };
    }, [connect]);

    const sendMessage = useCallback((message) => {
        if (socket && socket.readyState === WebSocket.OPEN) {
            socket.send(message);
        } else {
            messageQueue.current.push(message);
        }
    }, [isConnected, socket]);

    return {
        socket,
        isConnected,
        sendMessage,
    };
};

export default useWebSocket;
