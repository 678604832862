import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SERVER_ENDPOINT } from "../../../utils/constants";

const NotificationModal = ({ isOpen, onClose }) => {
  // const [visible, setVisible] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [loading, setLoading] = useState(false);
  const [announcement, setAnnouncement] = useState(null);
  const navigate = useNavigate();
  const popupRef = useRef(null);
  useEffect(() => {
    if (isOpen) {
      // Delay adding the "show" class to ensure smooth animation
      setTimeout(() => setShowPopup(true), 50);
    } else {
      setShowPopup(false);
    }
  }, [isOpen]);

  useEffect(() => {
    // Event listener for clicks outside of the popup
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        onClose(); // Close the popup if clicked outside
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup event listener on unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);
  // useEffect(() => {
  //   setVisible(true);
  // }, []);

  useEffect(() => {
    async function fetchTaskByScreen() {
      try {
        setLoading(true);
        const response = await fetch(
          `${SERVER_ENDPOINT}/v1/announcement/latestAnnouncement`,
          {
            method: "POST",
          }
        );
        if (response.status === 200) {
          const parsedRes = await response.json();
          setAnnouncement(parsedRes.data);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }
    fetchTaskByScreen();
  }, []);

  return (
    <div className="popup-overlay">
      <div
        ref={popupRef}
        className={`popup-container ${showPopup ? "show" : ""}`}
        style={{
          backgroundColor: "#1D1C1D",
          borderTopLeftRadius: "60px",
          borderTopRightRadius: "60px",
          borderTop: "5px solid yellow",
          position: "relative",
        }}
      >
        {announcement ? (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                marginBottom: "1rem",
                padding: "20px 20px",
              }}
            >
              <img
                src={announcement.image}
                alt=""
                width="80%"
                className="notificationModalImg"
                style={{ objectFit: "cover" }}
              />
            </div>
            <div>
              <button
                className="stripe-button"
                onClick={() => navigate("/" + announcement.url)}
              >
                Open
              </button>
            </div>
          </>
        ) : loading ? (
          <h2>Loading...</h2>
        ) : (
          <h1>No new announcements</h1>
        )}
      </div>
    </div>
  );
};

export default NotificationModal;
