import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react'
import { UserContext } from '../../../context/UserContext';
import { SERVER_ENDPOINT } from '../../../utils/constants';
import toast from 'react-hot-toast';
import { AuthContext } from '../../../context/AuthContext';

import light from "../../../media/V3_light.png";

export default function DailyBooster() {
    const [isLoading, setIsLoading] = useState(false);
    const [remainingTime, setRemainingTime] = useState("");
    const { setUser, setUserEnergy, user } = useContext(UserContext);
    const { jwt } = useContext(AuthContext);
    const {
        dailyCount,
        energyCoolDownActive,
        lastEnergyMaxAt,
    } = user;

    const getButtonText = () => {
        if (isLoading) return "Recharging...";
        if (energyCoolDownActive) {
            return 5 - (dailyCount || 0) === 0
                ? "Limit Reached"
                : `Cooldown: ${remainingTime}`;
        }
        return "Recharge Energy";
    };

    useEffect(() => {
        let timer;
        const updateTimer = () => {
            if (energyCoolDownActive && lastEnergyMaxAt) {
                const now = moment();
                const cooldownEnd = moment(lastEnergyMaxAt).add(1, "hour");
                const duration = moment.duration(cooldownEnd.diff(now));

                if (duration.asSeconds() <= 0) {
                    setUser((prevUser) => ({ ...prevUser, energyCoolDownActive: false }));
                    setRemainingTime("");
                } else {
                    const minutes = duration.minutes().toString().padStart(2, "0");
                    const seconds = duration.seconds().toString().padStart(2, "0");
                    setRemainingTime(`${minutes}:${seconds}`);
                }
            } else {
                setRemainingTime("");
            }
        };

        updateTimer();
        timer = setInterval(updateTimer, 1000);

        return () => {
            if (timer) clearInterval(timer);
        };
    }, [energyCoolDownActive, lastEnergyMaxAt, setUser]);

    async function handleEnergyFull() {
        setIsLoading(true);
        try {
            const response = await fetch(`${SERVER_ENDPOINT}/v1/user/energyMax`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${jwt}`,
                },
            });
            const parsedResponse = await response.json();
            if (parsedResponse.status === "SUCCESS") {
                setUserEnergy(parsedResponse.data.energy);
                setUser((user) => ({
                    ...user,
                    dailyCount: parsedResponse.data.dailyCount,
                    lastEnergyMaxAt: parsedResponse.data.lastEnergyMaxAt,
                    energyCoolDownActive: true,
                }));
                toast.success("Energy Refilled");
            } else throw new Error("User Limited reached for energy refill");
        } catch (error) {
            console.log(error);
            toast.error("Reached Daily Limit");
        } finally {
            setIsLoading(false);
        }
    }
    return (
        <button
            disabled={energyCoolDownActive || isLoading}
            style={{
                display: "flex",
                alignItems: "center",
                backgroundColor: "#333333",
                height: "6rem",
                width: "100%",
                border: "none",
                borderRadius: "20px",
                marginTop: "3rem",
            }}
            onClick={handleEnergyFull}
        >
            <img src={light} alt="light" />
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    gap: "0.2rem",
                }}
            >
                <div
                    style={{
                        fontSize: "1.5rem",
                        color: "white",
                        fontFamily: "roboto",
                        fontWeight: "400",
                    }}
                >
                    Full Energy
                </div>
                <div
                    style={{
                        fontSize: "1rem",
                        color: "#575757",
                        fontFamily: "roboto",
                        fontWeight: "400",
                    }}
                >
                    {getButtonText()}
                </div>
                <div
                    style={{
                        display: "flex",
                        gap: "0.4rem",
                    }}
                >
                    <span style={{ color: "white" }}>
                        {5 - (dailyCount || 0)}/5
                    </span>{" "}
                    <span style={{ color: "green" }}>Available</span>
                </div>
            </div>
        </button>
    )
}
