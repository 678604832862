import React, { createContext, useContext, useEffect, useState } from "react";

import moment from "moment";
import {
  clubIdxToNameMapping,
  clubLevelDetails,
  SERVER_ENDPOINT,
} from "../utils/constants";
import { AuthContext } from "./AuthContext";
import { UserContext } from "./UserContext";

export const ClubContext = createContext();

export const ClubContextProvider = ({ children }) => {
  const { jwt } = useContext(AuthContext);
  const [userClubs, setUserClubs] = useState([]);
  const [clubCredits, setClubCredits] = useState(0);
  const [userStars, setUserStars] = useState(0);
  const { setUserCredits } = useContext(UserContext);
  const [userClubRank, setUserClubRank] = useState("");

  async function fetchUserClub() {
    try {
      const resp = await fetch(`${SERVER_ENDPOINT}/v1/club/details`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwt}`,
        },
      });

      if (resp.status === 200) {
        const parsedRes = await resp.json();

        const clubs = parsedRes.clubLevel.map((level, idx) => {
          return {
            clubId: idx,
            clubName: clubIdxToNameMapping[idx],
            isOnUpgrade: parsedRes.isOnUpgrade[idx],
            isUnlocked: parsedRes.isUnLocked[idx],
            returnAmount: parsedRes.returnAmount[idx],
            upgradeStartAt: parsedRes.timeOfStart[idx],
            clubLevel: level,
          };
        });
        setUserClubs(clubs);
        setUserClubRank(parsedRes.userLevel);
        setUserStars(parsedRes.stars);
        setUserCredits(
          (credits) => credits + (parsedRes?.creditsRewarded || 0)
        );
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    if (jwt) {
      fetchUserClub();
    }
  }, [jwt]);

  useEffect(() => {
    // if some club is updating -> inc the credits per second
    const onUpgradeClubs = userClubs.filter((club) => club.isOnUpgrade);
    const perSecondInc = onUpgradeClubs.map((club) => {
      const { duration, invest, roi } = clubLevelDetails[club.clubLevel];
      return ((1 + roi / 100) * invest) / (duration * 60);
    });
    const alreadyGivenCredits = onUpgradeClubs
      .map((club, idx) => {
        const startTimeStamp = moment.utc(club.upgradeStartAt);
        return (
          moment().utc().diff(startTimeStamp, "seconds") * perSecondInc[idx]
        );
      })
      .reduce((prev, curr) => prev + curr, 0);
    const perSecondCredits = perSecondInc.reduce(
      (prev, curr) => prev + curr,
      0
    );
    setClubCredits(alreadyGivenCredits);
    const i = setInterval(() => {
      setClubCredits((credits) => credits + perSecondCredits);
    }, 1000);

    return () => clearInterval(i);
  }, [userClubs]);
  return (
    <ClubContext.Provider
      value={{
        userClubs,
        setUserClubs,
        clubCredits,
        userStars,
        userClubRank,
        setUserClubRank,
        setUserStars,
        fetchUserClub,
      }}
    >
      {children}
    </ClubContext.Provider>
  );
};
