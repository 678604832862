import React, { useEffect, useRef, useState } from "react";
const V3Popup = ({
  isOpen,
  onClose,
  children,
  congo,
  isTransactionProcessing,
  inCreaseHight,
}) => {
  const [showPopup, setShowPopup] = useState(false);
  const popupRef = useRef(null);

  useEffect(() => {
    if (isOpen) {
      // Delay adding the "show" class to ensure smooth animation
      setTimeout(() => setShowPopup(true), 50);
    } else {
      setShowPopup(false);
    }
  }, [isOpen]);

  useEffect(() => {
    // Event listener for clicks outside of the popup
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        if (isTransactionProcessing !== true) {
          onClose(); // Close the popup if clicked outside
        }
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  return isOpen ? (
    <div className="popup-overlay">
      <div
        ref={popupRef}
        className={`popup-container ${inCreaseHight ? "inCreaseHeight" : ""} ${
          showPopup ? "show" : ""
        }`}
        style={{
          backgroundColor: "#1D1C1D",
          borderTopLeftRadius: "60px",
          borderTopRightRadius: "60px",
          borderTop: "5px solid yellow",
          position: "relative",
        }}
      >
        {/* Render children inside the popup */}
        {children}
      </div>
    </div>
  ) : null;
};

export default V3Popup;
