import { useSpring } from "@react-spring/web";
import React from "react";
import v3YtImg from "../../media/v3_YtLogo.png";
import v3TgImg from "../../media/v3_tgLogo.png";
import v3XImg from "../../media/v3_xLogo.png";
import { getMobileOperatingSystem } from "../../utils/helpers";
import "./loadingIndex.css";
import { BiLogoTelegram } from "react-icons/bi";
import { FaYoutube } from "react-icons/fa6";

const LoadingIndex = ({ setShowLoading }) => {
  const CopyAnimation = useSpring({
    from: { opacity: 1 },
    to: { opacity: 0 },
    config: { duration: 1400 },
    loop: { reverse: true },
  });

  const openUrl = (url) => {
    if (getMobileOperatingSystem() === "iOS") {
      window.location.href = url;
    } else {
      window.open(url);
    }
  };

  return (
    <div className="content">
      <div className="tapToEarnMainDiv">
        <div className="allIcons">
          <a
            href="https://www.youtube.com/@frogbar"
            onClick={(e) => { e.preventDefault(); openUrl("https://www.youtube.com/@frogbar"); }}
            style={{
              background: "rgba(0, 0, 0, 1)",
              height: "40px",
              width: "40px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "50%",
              border: "2px solid #BFA75D",
              margin: "0 5px"
            }}
          >
            <FaYoutube color="#FF0000" fontSize="25px" />
          </a>
          <a
            href="https://x.com/pepefrogbar"  // Link for the other icon
            onClick={(e) => { e.preventDefault(); openUrl("https://x.com/pepefrogbar"); }}
            style={{
              background: "rgba(0, 0, 0, 1)",
              height: "40px",
              width: "40px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "50%",
              border: "2px solid #BFA75D",
              margin: "0 5px"
            }}
          >
            <img src={v3XImg} alt="x" height="40px" width="40px" />
          </a>
          <a
            href="https://t.me/pepefrogbar"  // Telegram link
            onClick={(e) => { e.preventDefault(); openUrl("https://t.me/pepefrogbar"); }}
            style={{
              background: "rgba(0, 0, 0, 1)",
              height: "40px",
              width: "40px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "50%",
              border: "2px solid #BFA75D",
              margin: "0 5px"
            }}
          >
            <BiLogoTelegram color="#229ED9" fontSize="30px" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default LoadingIndex;
