import { useTonAddress } from "@tonconnect/ui-react";
import React, { useContext, useEffect, useState } from "react";
import { MdDoubleArrow } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";
import { UserContext } from "../../../context/UserContext";
import blueDiamondIcon from "../../../media/blueDiamond.png";
import bronzeIcon from "../../../media/bronze.png";
import buyNow from "../../../media/buy-now-3.gif";
import candleStick from "../../../media/candlestickChart.gif";
import crownDiamondIcon from "../../../media/crownDiamond.png";
import diamondIcon from "../../../media/diamond.png";
import goldIcon from "../../../media/gold.png";
import level1Icon from "../../../media/level1.png";
import level10Icon from "../../../media/level10.png";
import level2Icon from "../../../media/level2.png";
import level3Icon from "../../../media/level3.png";
import level4Icon from "../../../media/level4.png";
import level5Icon from "../../../media/level5.png";
import level6Icon from "../../../media/level6.png";
import level7Icon from "../../../media/level7.png";
import level8Icon from "../../../media/level8.png";
import level9Icon from "../../../media/level9.png";
import platinumIcon from "../../../media/platinum.png";
import profileIcon from "../../../media/profile_Image.png";
import rookie from "../../../media/rookie.png";
import silverIcon from "../../../media/silver.png";
import v3PremiumTick from "../../../media/v3_coffeeTick.png";
import slide from "../../../media/v3_slide2.png";
import { rankToStringMapping } from "../../../utils/constants";
import { truncateName } from "../../../utils/helpers";
import styles from "./ProfileBar.module.css";

const ProfileBar = ({ setIsSettings, isSettingsShown }) => {
  const [showConnect, setShowConnect] = useState(false);
  const [barWidth, setBarWidth] = useState(0);

  const { user, showConnectWallet, setShowConnectWallet } =
    useContext(UserContext);
  const { notification } = useContext(AuthContext);
  const { kycCompleted, isPremiumUser } = user;
  const { jwt } = useContext(AuthContext);
  const [userProfileImg, setUserProfileImg] = useState(user.profileImgUrl);
  const connectedAddress = useTonAddress();
  const navigate = useNavigate();

  useEffect(() => {
    if (!jwt) {
      navigate("/");
    }
  }, [user]);

  const handleConnect = () => {
    console.log(connectedAddress);
    if (connectedAddress !== "") {
      navigate("/wallet?buy=false");
    } else {
      setShowConnectWallet(true);
    }
  };

  const handleBuyNowClick = () => {
    navigate("/privateSale");
  };

  useEffect(() => {
    const finalWidth =
      ((user.nextRankUpAt - user.invitesToRankUp) / user.nextRankUpAt) * 100; // Calculate the width
    setTimeout(() => {
      setBarWidth(finalWidth); // Set the final width after a small delay
    }, 100); // Small delay before starting the animation
  }, [user]);

  const getLevelImage = (levelNo) => {
    switch (levelNo) {
      case 0:
        return level1Icon;
      case 1:
        return level2Icon;
      case 2:
        return level3Icon;
      case 3:
        return level4Icon;
      case 4:
        return level5Icon;
      case 5:
        return level6Icon;
      case 6:
        return level7Icon;
      case 7:
        return level8Icon;
      case 8:
        return level9Icon;
      case 9:
        return level10Icon;
    }
  };

  const getFrameImage = (rankNo) => {
    switch (rankNo) {
      case 0:
        return rookie;
      case 1:
        return bronzeIcon;
      case 2:
        return silverIcon;
      case 3:
        return goldIcon;
      case 4:
        return platinumIcon;
      case 5:
        return diamondIcon;
      case 6:
        return blueDiamondIcon;
      case 7:
        return crownDiamondIcon;
    }
  };

  return (
    <div className={styles.welcomeContainer2}>
      <div className={styles.ProfileMainDiv}>
        <div
          style={{
            display: "flex",
            gap: "5px",
            width: "9.5rem",
            marginTop: "1rem",
          }}
        >
          <div className={styles.profileIconDiv}>
            <img
              src={profileIcon}
              alt="message icon"
              height="45px"
              width="45px"
              style={{ objectFit: "cover" }}
            />
          </div>
          <div>
            <div className={styles.profileInfoDiv}>
              <span className={styles.profileName} style={{ fontSize: "9px" }}>
                @{truncateName(user.name, 8)}
              </span>
              {/* {kycCompleted && (
                <img
                  src={v3BlueTick}
                  alt="message icon"
                  height="20px"
                  width="20px"
                />
              )} */}
              {isPremiumUser && (
                <img
                  src={v3PremiumTick}
                  alt="message icon"
                  height="25px"
                  width="25px"
                  style={{ objectFit: "cover", marginRight: "-3px" }}
                />
              )}
            </div>
            <div style={{ width: "100px" }}>
              <div className={styles.profileRankInfo}>
                <span style={{ fontSize: "8px" }}>
                  {rankToStringMapping[user.rank]}
                </span>
                <div
                  style={{ display: "flex", alignItems: "center", gap: "2px" }}
                >
                  {/*<span style={{ fontSize: "8px" }}>
                    {user.nextRankUpAt - user.invitesToRankUp}/
                    {user.nextRankUpAt}
                  </span>*/}
                  <MdDoubleArrow
                    className={styles.arrowIcon}
                    onClick={() => {
                      navigate("/friend?bonus=true");
                    }}
                  />
                </div>
              </div>
              <div className={styles.levelscore} style={{ width: "100%" }}>
                <div className={styles.progressBox}>
                  <div
                    className={styles.greenBar}
                    style={{
                      width: `${barWidth}%`,
                    }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* {isSettingsShown && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
              position: "relative",
            }}
          >
            <img src={v3MessageIcon} alt="message icon" />
            {notification && (
              <div style={{ background: "red" }}>
                <img
                  src={notificationBar}
                  alt=""
                  style={{
                    position: "absolute",
                    right: "92%",
                    top: "25%",
                    zIndex: "1",
                    width: "107px",
                    height: "27px",
                  }}
                />
                <span
                  style={{
                    position: "absolute",
                    color: "black",
                    right: "102%",
                    top: "28%",
                    zIndex: "999",
                    fontSize: "8px",
                    width: "100px",
                  }}
                >
                  {"Someone just purchased package of " + notification + " SOL"}
                </span>
              </div>
            )}

            <img
              src={v3_gearIcon}
              alt="message icon"
              className={styles.settingsIcon}
              onClick={() => setIsSettings(true)}
            />
          </div>
        )} */}

        <div
          style={{
            width: "13rem",
            marginLeft: "0.5rem",
            position: "relative",
          }}
        >
          <img
            src={slide}
            alt="slide bg"
            style={{
              width: "106%", // 80% of the viewport width
              position: "absolute",
              top: "0",
              left: "0",
            }}
          />
          {isPremiumUser && (
            <img
              src={candleStick}
              alt="candle logo"
              style={{
                width: "213px",
                height: "58px",
                // marginRight: "10rem",
                // marginTop: "0.6rem",
                position: "absolute",
                bottom: "0.9rem",
                left: "-1.1rem",
                opacity: 0.5,
              }}
            />
          )}
          {isPremiumUser ? (
            <div
              style={{
                position: "absolute",
                bottom: "1.8rem",
                left: "1rem",
              }}
            >
              <span
                style={{ fontSize: "0.7rem", fontFamily: "Zen Dots, Orbitron" }}
              >
                {user.pepeCredits.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </span>
            </div>
          ) : (
            <div
              style={{
                position: "absolute",
                bottom: "1.2rem",
                left: "1rem",
              }}
            >
              <img
                alt="buy now img"
                style={{
                  width: "7.5rem",
                  height: "0.8rem",
                  objectFit: "cover",
                  borderRadius: "20px",
                }}
                onClick={handleBuyNowClick}
                src={buyNow}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProfileBar;
