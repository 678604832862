import "./palay.css";
import Play from "./PlayModal/Play";

import BottomButtons from "../../app/Shared/BottomNavigation/bottomButtons"; // Ensure the path and name are correct
import { useContext, useEffect, useState } from "react";
import CalendarModal from "./CalendarModal";
import WalletModal from "./WalletModal";
import { energyRankMapping, SERVER_ENDPOINT } from "../../utils/constants";
import { UserContext } from "../../context/UserContext";
import { AuthContext } from "../../context/AuthContext";
import usePageVisibility from "../../hooks/usePageVisibility";
import moment from "moment";
import AnnouncementModal from "./AnnouncementModal";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

const PlayIndex = ({ tokenPrice, connectedAddress }) => {
  const {
    user,
    showConnectWallet,
    setShowConnectWallet,
    setUserCredits,
    setUserEnergy,
    showReturnPopup,
  } = useContext(UserContext);
  const isVisible = usePageVisibility();
  const { consecutiveLoginDays, rank, userID } = user;
  const [tap, setTap] = useState(0);
  const [isOpen, setIsOpen] = useState(showReturnPopup);
  const [lastTapAt, setLastTapAt] = useState(null);
  const [firstTapAt, setFirstTapAt] = useState(null);
  const [isAnnouncement, setIsAnnouncement] = useState(false);
  const [newAnnouncement, setNewAnnouncement] = useState();
  const [blink, setBlink] = useState(true);
  const { jwt } = useContext(AuthContext);
  const navigate = useNavigate();
  const MAX_ENERGY = energyRankMapping[rank];

  useEffect(() => {
    async function handleTapServerUpdate() {
      try {
        const response = await fetch(`${SERVER_ENDPOINT}/v1/user/tap`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwt}`,
          },
          body: JSON.stringify({
            tap,
            lastTapAt,
            firstTapAt,
          }),
        });
        const parsedResponse = await response.json();
        if (parsedResponse.status == 429) {
          toast.error(parsedResponse.message);
        }
        setUserCredits(parseInt(parsedResponse.data.credits));
        setUserEnergy(parsedResponse.data.energy);
        setLastTapAt(null);
        setFirstTapAt(null);
        setTap(0);
      } catch (error) {
        console.log(error);
      }
    }
    const serverUpdateTimeout = setTimeout(
      () => tap && lastTapAt && handleTapServerUpdate(),
      500
    );
    const perSecondROR = energyRankMapping[rank] / 3600;

    let i1;
    let t1;

    i1 = setInterval(
      () =>
        setUserEnergy((energy) => Math.min(perSecondROR + energy, MAX_ENERGY)),
      1000
    );

    return () => {
      clearTimeout(t1);
      clearTimeout(serverUpdateTimeout);
      clearInterval(i1);
    };
  }, [tap, setUserEnergy, setUserCredits, rank]);

  useEffect(() => {
    async function syncEnergy() {
      try {
        const response = await fetch(
          `${SERVER_ENDPOINT}/v1/user/energy/${userID}`,
          {
            method: "GET",
          }
        );
        const parsedRes = await response.json();
        if (parsedRes.status === "SUCCESS")
          setUserEnergy(parsedRes.data.energy);
      } catch (error) {
        console.log("Failed to sync energy with server");
      }
    }
    if (isVisible) syncEnergy();
  }, [setUserEnergy, isVisible]);

  useEffect(() => {
    async function announcement() {
      try {
        const response = await fetch(
          `${SERVER_ENDPOINT}/v1/announcement/latestAnnouncement`,
          {
            method: "POST",
          }
        );
        const parsedRes = await response.json();
        if (parsedRes.status === "SUCCESS") setNewAnnouncement(parsedRes.data);
      } catch (error) {
        console.log("Failed to get announcement with server");
      }
    }
    announcement();
  }, []);

  useEffect(() => {
    if (user?.latestAnnouncement != newAnnouncement?.announcementID) {
      setBlink(true);
    }
    if (user?.latestAnnouncement == newAnnouncement?.announcementID) {
      setBlink(false);
    }
    if (
      isAnnouncement &&
      user?.latestAnnouncement != newAnnouncement?.announcementID
    ) {
      const latestAnnouncement = newAnnouncement.announcementID;
      async function updateUserLatestAnnouncement() {
        try {
          const response = await fetch(
            `${SERVER_ENDPOINT}/v1/announcement/updateUserAnnouncement`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${jwt}`,
              },
              body: JSON.stringify({
                latestAnnouncement,
              }),
            }
          );
          user.latestAnnouncement = newAnnouncement.announcementID;
          const parsedResponse = await response.json();
          setBlink(false);
        } catch (error) {
          console.log(error);
        }
      }

      updateUserLatestAnnouncement();
    }
  }, [isAnnouncement]);

  useEffect(() => {
    if (user?.latestAnnouncement != newAnnouncement?.announcementID) {
      setBlink(true);
    }
    if (user?.latestAnnouncement == newAnnouncement?.announcementID) {
      setBlink(false);
    }
  }, [newAnnouncement]);

  return (
    <div
      style={{
        width: "100%",
        height: "100vh",
        position: "fixed",
        overflow: "hidden",
      }}
    >
      <Play
        tokenPrice={tokenPrice}
        tap={tap}
        setTap={setTap}
        setLastTapAt={setLastTapAt}
        setFirstTapAt={setFirstTapAt}
        firstTapAt={firstTapAt}
        showConnectWallet={showConnectWallet}
        setShowConnectWallet={setShowConnectWallet}
        connectedAddress={connectedAddress}
        setIsAnnouncement={setIsAnnouncement}
        newAnnouncement={newAnnouncement}
        blink={blink}
      />
      <WalletModal
        connectedAddress={connectedAddress}
        showConnectWallet={showConnectWallet}
        setShowConnectWallet={setShowConnectWallet}
      />
      {/* <div
        className="calenderModalpfx"
        style={{ display: isOpen ? "flex" : "none" }}
      >
        <CalendarModal
          {...{ isOpen, setIsOpen }}
          consecutiveLoginDays={consecutiveLoginDays}
        />
      </div> */}
      <div
        className="calenderModalpfx"
        style={{ display: isAnnouncement ? "flex" : "none" }}
      >
        <AnnouncementModal
          {...{ isAnnouncement, setIsAnnouncement, newAnnouncement, blink }}
        />
      </div>
      <BottomButtons />
    </div>
  );
};

export default PlayIndex;
