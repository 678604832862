import React, { createContext, useContext, useEffect, useState } from "react";
import {
  motorIdxToNameMapping,
  motorLevelDetails,
  SERVER_ENDPOINT,
} from "../utils/constants";
import { AuthContext } from "./AuthContext";
import { UserContext } from "./UserContext";
import moment from "moment";

export const MotorContext = createContext();

export const MotorContextProvider = ({ children }) => {
  const { jwt } = useContext(AuthContext);
  const { setUserCredits } = useContext(UserContext);
  const [userMotors, setUserMotors] = useState([]);
  const [motorCredits, setMotorCredits] = useState(0);
  async function fetchUserMotors() {
    try {
      const resp = await fetch(`${SERVER_ENDPOINT}/v1/motor/details`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwt}`,
        },
      });

      if (resp.status === 200) {
        const parsedRes = await resp.json();

        const motors = parsedRes.motorLevel.map((level, idx) => {
          return {
            motorId: idx,
            motorName: motorIdxToNameMapping[idx],
            isOnUpgrade: parsedRes.isOnUpgrade[idx],
            isUnlocked: parsedRes.isUnLocked[idx],
            upgradeStartAt: parsedRes.timeOfStart[idx],
            motorLevel: level,
          };
        });
        setUserMotors(motors);
      }
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    if (jwt) {
      fetchUserMotors();
    }
  }, [jwt]);

  useEffect(() => {
    // if some motor is updating -> inc the credits per second
    const onUpgradeMotors = userMotors.filter((motor) => motor.isOnUpgrade);
    const perSecondInc = onUpgradeMotors.map((motor) => {
      const { duration, invest, roi } = motorLevelDetails[motor.motorLevel];
      return ((1 + roi / 100) * invest) / (duration * 60);
    });
    const alreadyGivenCredits = onUpgradeMotors
      .map((motor, idx) => {
        const startTimeStamp = moment.utc(motor.upgradeStartAt);
        return (
          moment().utc().diff(startTimeStamp, "seconds") * perSecondInc[idx]
        );
      })
      .reduce((prev, curr) => prev + curr, 0);
    const perSecondCredits = perSecondInc.reduce(
      (prev, curr) => prev + curr,
      0
    );
    setMotorCredits(alreadyGivenCredits);
    const i = setInterval(() => {
      setMotorCredits((credits) => credits + perSecondCredits);
    }, 1000);

    return () => clearInterval(i);
  }, [userMotors]);
  return (
    <MotorContext.Provider
      value={{ userMotors, setUserMotors, motorCredits, fetchUserMotors }}
    >
      {children}
    </MotorContext.Provider>
  );
};
