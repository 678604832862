// import React, { useEffect, useRef, useState } from "react";
// import connIcon2 from "../../media/connIcon2.png";
// import { TonConnectButton, WalletNotConnectedError } from "@tonconnect/ui-react";
// import Confetti from 'react-confetti';
// import { IoCloseCircle } from "react-icons/io5";
// import { useSpring, animated } from "@react-spring/web";

// const manifestUrl =
// "https://raw.githubusercontent.com/ton-community/tutorials/main/03-client/test/public/tonconnect-manifest.json";

// const WalletModal = ({showConnectWallet, setShowConnectWallet, connectedAddress}) => {
//   // const isOpen = true;
//   const [showConfetti, setShowConfetti] = useState(false);
//   const modalRef = useRef(null);
//   const [visible, setVisible] = useState(false);
  
//   const handleCOnfetti = () => {
//     setShowConfetti(true);
//     console.log("confetti CLicked")
//   }
  
//   const buyBoxAnimation = useSpring({
//     from: { transform: "translateY(-100%)", opacity: 0 },
//     to: { transform: visible ? "translateY(0%)" : "translateY(100%)", opacity: visible ? 1 : 0 },
//     config: { duration: 500 },
//   });

//   const handleClickOutside = (event) => {
//     if (modalRef.current && !modalRef.current.contains(event.target)) {
//       setShowConnectWallet(false);
//       console.log("clickedoutside")
//     }
//   };

//   useEffect(() => {
//     if (showConnectWallet) {
//       setVisible(true);
//       document.addEventListener("mousedown", handleClickOutside);
//     } else {
//       document.removeEventListener("mousedown", handleClickOutside);
//     }

//     return () => {
//       document.removeEventListener("mousedown", handleClickOutside);
//     };
//   }, [showConnectWallet]);
  
//   return (
//       showConnectWallet && connectedAddress == "" && (
//     <div className="walletModalpfx"  style={{display: showConnectWallet ? "flex" : "none" }} >
//     <div style={{ zIndex: 1000 }}>
//           <div className="connect-wallet-container"  >
          
//           {showConfetti && <div className="confetti"> <Confetti width={650} recycle={false} /></div>}
//           <animated.div style={{ ...buyBoxAnimation }}>
//           <div className="connect-wallet-content" ref={modalRef}>
//             <div style={{width: "100%", textAlign: "right"}} onClick={() => {setShowConnectWallet(false)}}><IoCloseCircle className="closeIcon_ax" /></div>
//             <div className="wallet-content">
//               <div className="wallet-icon2">
//                 <img src={connIcon2} className="connIcon2" />
//               </div>
//               <h1>CONNECT YOUR TON WALLET</h1>
//               <p>
//                 Connect your crypto ton wallet. If you don't have one create it
//                 in your telegram account.
//               </p>
//               <div onClick={handleCOnfetti}><TonConnectButton   manifestUrl={manifestUrl} /></div>
//               {/* <button onClick={handleWalletConnect}>CONNECT</button> */}
//               {/* <div style={{width: "5px", 'height': "100px"}}></div> */}
//             </div>
//           </div>
//           </animated.div>
//         </div>
      
//     </div>
//     </div>
//   )

//   );
// };

// export default WalletModal;

import React, { useEffect, useRef, useState } from "react";
import connIcon2 from "../../media/connIcon2.png";
import { TonConnectButton, WalletNotConnectedError, useTonConnectUI } from "@tonconnect/ui-react";
import Confetti from 'react-confetti';
import { IoCloseCircle } from "react-icons/io5";
import { useSpring, animated } from "@react-spring/web";

const manifestUrl = "https://raw.githubusercontent.com/ton-community/tutorials/main/03-client/test/public/tonconnect-manifest.json";

const WalletModal = ({ showConnectWallet, setShowConnectWallet, connectedAddress }) => {
  const [showConfetti, setShowConfetti] = useState(false);
  const modalRef = useRef(null);
  const [visible, setVisible] = useState(false);
  const [renderContent, setRenderContent] = useState(false);
  const [tonConnectUI, setOptions] = useTonConnectUI();

  const handleConfetti = () => {
    setShowConfetti(true);
    console.log("confetti Clicked");
  };

  const buyBoxAnimation = useSpring({
    from: { transform: "translateY(100%)", opacity: 0 },
    to: { transform: visible ? "translateY(0%)" : "translateY(100%)", opacity: visible ? 1 : 0 },
    config: { duration: 500 },
  });

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      setShowConnectWallet(false);
      console.log("clicked outside");
    }
  };

  useEffect(() => {
    if (showConnectWallet) {
      setVisible(true);
      document.addEventListener("mousedown", handleClickOutside);
      setTimeout(() => setRenderContent(true), 100); // Add a small delay before rendering the content
    } else {
      setVisible(false);
      setRenderContent(false);
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showConnectWallet]);

  return (
    showConnectWallet && connectedAddress === "" && (
      <div className="walletModalpfx" style={{ display: showConnectWallet ? "flex" : "none" }}>
        <div style={{ zIndex: 1000 }}>
          <div className="connect-wallet-container">
            {showConfetti && (
              <div className="confetti">
                <Confetti width={650} recycle={false} />
              </div>
            )}
            {renderContent && (
              <animated.div style={{ ...buyBoxAnimation }}>
                <div className="connect-wallet-content" ref={modalRef}>
                  <div style={{ width: "100%", textAlign: "right" }} onClick={() => setShowConnectWallet(false)}>
                    <IoCloseCircle className="closeIcon_ax" />
                  </div>
                  <div className="wallet-content">
                    <div className="wallet-icon2">
                      <img src={connIcon2} className="connIcon2" />
                    </div>
                    <h1>CONNECT YOUR TON WALLET</h1>
                    <p>
                      Connect your crypto ton wallet. If you don't have one create it in your telegram account.
                    </p>
                    <div onClick={handleConfetti}>
                      <TonConnectButton manifestUrl={manifestUrl} />
                    </div>
                  </div>
                </div>
              </animated.div>
            )}
          </div>
        </div>
      </div>
    )
  );
};

export default WalletModal;
