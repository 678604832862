import React, { useEffect, useState } from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { SERVER_ENDPOINT } from "../utils/constants";

const useServerTimeSync = () => {
  const [serverTime, setServerTime] = useState(moment().utc());
  const navigate = useNavigate();

  useEffect(() => {
    const fetchServerTime = async () => {
      try {
        const response = await fetch(`${SERVER_ENDPOINT}/server-timestamp`, {
          method: "GET",
        });
        const parsedResponse = await response.json();
        if (parsedResponse.status === "SUCCESS")
          setServerTime(moment(parsedResponse.data.serverTime).utc());
        else throw new Error("Failed to fetch timestamp");
      } catch (error) {
        console.log(error);
      }
    };
    fetchServerTime();
    const intervalId = setInterval(fetchServerTime, 60000); // Sync every minute
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    const timeDifferenceInMinutes = Math.abs(moment().utc().diff(serverTime, "minutes"));
    if (timeDifferenceInMinutes > 5) {
      navigate("/unsupported");
    }
  }, [serverTime, navigate]);

  return Math.abs(moment().utc().diff(serverTime, "minutes")) <= 5;
};

export default useServerTimeSync;