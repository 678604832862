import React, { useContext, useEffect, useState } from "react";
import "./minigameIndex.css";
import creditCoin from "../../../media/tap_credits.png";
import miniGameTopImg from "../../../media/miniGameTopImg.png";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../../context/UserContext";
import { SERVER_ENDPOINT } from "../../../utils/constants";
import { AuthContext } from "../../../context/AuthContext";
import moment from "moment";
import CustomPopup from "../../Shared/CustomPopup/CustomPopup";
import toast from "react-hot-toast";
import useTelegramBackButton from "../../../utils/backButton";

const MiniGameIndex = () => {
  const navigate = useNavigate();
  const { jwt } = useContext(AuthContext);
  const { userCredits } = useContext(UserContext);
  const [tickets, setTickets] = useState(0);
  const [playAvailable, setPlayAvailable] = useState(false);
  const [resRecieved, setResRecieved] = useState(false);
  const [remTime, setRemTime] = useState(0);
  const [timeLeft, setTimeLeft] = useState(remTime);
  const [showRulesModal, setShowRulesModal] = useState(false);

  useEffect(() => {
    const handlePageLoad = () => {
      const hasLoaded = sessionStorage.getItem("hasLoaded");
      if (hasLoaded) {
        // This is a reload, navigate to root
        navigate("/");
      } else {
        // This is the first load, set the flag
        sessionStorage.setItem("hasLoaded", "true");
      }
    };
    handlePageLoad();
    // Cleanup function to remove the flag when component unmounts
    return () => {
      sessionStorage.removeItem("hasLoaded");
    };
  }, [navigate]);
    useTelegramBackButton();

  function playNow() {
    if (playAvailable) navigate("/mini-game-2");
    else {
      if (tickets === 3) toast.error("Reached Daily Limit");
      else {
        toast.error(
          `Cannot play again for ${Math.ceil(timeLeft / 60)} minutes`
        );
      }
      // else toast.error(`Cannot play again for ${Math.ceil(timeLeft)} seconds`);
    }
    // navigate("/mini-game-2");
  }

  const formatTime = (seconds) => {
    const hrs = Math.floor(seconds / 3600);
    const mins = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    return `${hrs > 0 ? `${hrs}:` : ""}${mins
      .toString()
      .padStart(2, "0")}:${secs.toString().padStart(2, "0")}`;
  };
  // const formatTime = (seconds) => {
  //   const mins = Math.floor(seconds / 60);
  //   const secs = seconds % 60;
  //   return `${mins > 0 ? `${mins}:` : ""}${secs.toString().padStart(2, "0")}`;
  // };


  useEffect(() => {
    // When remTime changes, reset the timer
    setTimeLeft(remTime);
  }, [remTime]);

  useEffect(() => {
    async function isAvailable() {
      try {
        const response = await fetch(
          `${SERVER_ENDPOINT}/v1/puzzle/isAvailable`,
          {
            method: "POST",
            headers: {
              "Content-type": "application/json",
              Authorization: `Bearer ${jwt}`,
            },
          }
        );
        const parsedResponse = await response.json();
        console.log(parsedResponse);
        if (parsedResponse.status == "SUCCESS") {
          setPlayAvailable(true);
          setResRecieved(true);
          setTickets(parsedResponse.data.playCount);
          setRemTime(0);
        }
        if (parsedResponse.status == "FAILED") {
          setResRecieved(true);
          setPlayAvailable(false);
          setTickets(
            parsedResponse.error.playCount >= 3
              ? 3
              : parsedResponse.error.playCount
          );
          if (parsedResponse.error.playCount >= 3) {
            setRemTime(parsedResponse.error.lastPlayTime);
          } else {
            setRemTime(60 * 30 - parsedResponse.error.lastPlayTime);
            // setRemTime(60 - parsedResponse.error.lastPlayTime);
          }
        }
      } catch (error) {
        setPlayAvailable(false);
        console.log(error);
      }
    }

    isAvailable();
  }, []);


  useEffect(() => {
    console.log(resRecieved)
    const interval = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime <= 1) {
          clearInterval(interval); // Stop the timer when it reaches 0
          if (resRecieved) {
            setPlayAvailable(true);
          }
          console.log(resRecieved, playAvailable);
          return 0; // Ensure timeLeft is set to exactly 0
        }
        return prevTime - 1;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [resRecieved]);

  const handleRules = () => {
    if (navigator.vibrate) {
      navigator.vibrate(50); // vibration on tap
    } else if (window.Telegram?.WebApp)
      window.Telegram?.WebApp.HapticFeedback.notificationOccurred("success");
    setShowRulesModal(!showRulesModal);
  };

  const handleBack = () => {
    if (navigator.vibrate) {
      navigator.vibrate(50); // vibration on tap
    } else if (window.Telegram?.WebApp)
      window.Telegram?.WebApp.HapticFeedback.notificationOccurred("success");
    navigate("/?noload=true");
  };
  return (
    <div className="miniGameMainDiv">
      <div className="miniGameContentWrapper">
        <div className="topButton">
          <button className="miniGameBackBtn" onClick={() => handleBack()}>
            Back
          </button>
          <button className="miniGameRules" onClick={() => handleRules()}>
            Rules
          </button>
        </div>
        <div className="logoImgSection">
          <div className="TopImgSection">
            <img src={miniGameTopImg} alt="" className="miniGameTopImg" />
          </div>
        </div>
        {/* win ammount */}
        <div className="ammountMainBox">
          <div className="winnerAmmountBox">WIN UPTO 2,000,000</div>
        </div>
        <div className="creditCoinMain">
          <div className="gameCreditBox">
            <img src={creditCoin} alt="coin" className="coinImage" />
            <div>
              <p className="creditTitle">My Tap Credits</p>
              <p className="amount">{userCredits}</p>
            </div>
          </div>
        </div>
        {/*  */}
        <div className="ticketMainBox">
          <div className="ticketBox">
            <p className="ticketTitle">TICKET: {tickets}/3</p>
          </div>

          {!playAvailable && (
            <div className="ticketBox2">
              <p className="newTicket">new tickets in {formatTime(timeLeft)}</p>
            </div>
          )}
        </div>
        <div className="buttonSection">
          <button
            onClick={playNow}
            className="playNowButton"
            disabled={!playAvailable}
          >
            {!playAvailable ? formatTime(timeLeft) : "Play Now"}
          </button>
        </div>
        {showRulesModal && (
          <CustomPopup
            showRulesModal={showRulesModal}
            setShowRulesModal={setShowRulesModal}
          />
        )}
      </div>
    </div>
  );
};

export default MiniGameIndex;
