import React, { useContext } from "react";
import styles from "./Friend.module.css";
import v3RankIcon from "../../../media/rank-icon.png";
import v3_BronzIcon from "../../../media/v3_rankBronz.png";
import v3_Silver from "../../../media/v3_rankSilver.png";
import v3_Gold from "../../../media/v3_rankGold.png";
import v3_Platinam from "../../../media/v3_rankPlatinam.png";
import v3_Diamond from "../../../media/v3_rankDaimond.png";
import v3_blueDaimond from "../../../media/v3_rankBlueDaimond.png";
import v3_crown from "../../../media/v3_rankCrown.png";
import {
  energyRankMapping,
  rankToStringMapping,
  rankToStringMappingTest,
} from "../../../utils/constants";
import { UserContext } from "../../../context/UserContext";

const BonusList = ({ setShowList }) => {
  const { user } = useContext(UserContext);
  const { rank } = user;
  const imagesArray = [
    v3_BronzIcon,
    v3_BronzIcon,
    v3_Silver,
    v3_Gold,
    v3_Platinam,
    v3_Diamond,
    v3_blueDaimond,
    v3_crown,
  ];

  return (
    <div>
      <div className={styles.closeList} onClick={() => setShowList(false)}>
        <span>CLOSE BONUSES LIST</span>
      </div>

      <div>
        <div className={styles.bonusListDiv}>
          <table>
            <thead
              style={{
                borderBottom: "1px solid white",
                textAlign: "left",
              }}
            >
              <th style={{ paddingLeft: "15px" }}>RANK</th>
              <th style={{ paddingLeft: "0px" }}>ENERGY</th>
              <th style={{ paddingLeft: "5px" }}>INVITE</th>
            </thead>
            <tbody className={styles.tableBody}>
              {rankToStringMappingTest.map((item, index) => (
                <tr style={{ textAlign: "left", ...(index === rank ? { backgroundColor: "#bfa75d" } : {}) }}>
                  <td
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "3px",
                      borderRadius: "0px",
                      padding: "0px",
                    }}
                  >
                    <img
                      src={imagesArray[index]}
                      alt=""
                      className={styles.listProfileIcon}
                    />
                    <span>{item.rankName}</span>
                  </td>
                  <td style={{ padding: "0px", borderRadius: 0 }}> {item.energyHour} </td>
                  <td style={{ fontSize: "15px", borderRadius: 0, padding: "0px" }}>
                    {" "}
                    {item.invite} FRIENDS
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div >
  );
};

export default BonusList;
/*
 <tr style={{ textAlign: "left" }}>
                <td
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "3px",
                    borderRadius: "0px",
                  }}
                >
                  <img src={v3RankIcon} alt="" />
                  <div>name</div>
                </td>
                <td>world</td>
                <td>india</td>
              </tr>

*/
