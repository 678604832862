import { useTonAddress, useTonConnectUI } from "@tonconnect/ui-react";
import React, { useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Grid } from "react-loader-spinner";
import { AuthContext } from "../../../context/AuthContext";
import { HotelContext } from "../../../context/HotelContext";
import { MotorContext } from "../../../context/MotorContext";
import { UserContext } from "../../../context/UserContext";
import sports from "../../../media/clubs & motors/dealer_comfort.png";
import Luxury from "../../../media/clubs & motors/dealer_luxury.png";
import v3motorBikeImg from "../../../media/clubs & motors/dealer_motorcycle.png";
import Jets from "../../../media/clubs & motors/dealer_private_jets.png";
import v3Suvis from "../../../media/clubs & motors/dealer_suvs.png";
import Yacht from "../../../media/clubs & motors/dealer_yatch_club.png";
import v3GoldIcon from "../../../media/silver-pepe-bg.png";
import v3caffeMocha from "../../../media/v3_caffeMocha.png";
import v3cappucino from "../../../media/v3_cappucino.png";
import v3CoffeeTick from "../../../media/v3_coffeeTick.png";
import v3espresso from "../../../media/v3_espresso.png";
import v3iceCoffee from "../../../media/v3_iceCoffee.png";
import v3macchiato from "../../../media/v3_macchiato.png";
import greentick from "../../../media/v3green-tick.png";
import silvertick from "../../../media/v3silver-tick.png";
// hotels image
import Austria from "../../../media/hotel/frogbar_austria.png";
import China from "../../../media/hotel/frogbar_china.png";
import {
  default as Greece,
  default as Thailand,
} from "../../../media/hotel/frogbar_greece.png";
import Hawaii from "../../../media/hotel/frogbar_hawaii.png";
import Holland from "../../../media/hotel/frogbar_holland.png";
import {
  default as Egypt,
  default as Nigeria,
} from "../../../media/hotel/frogbar_nigeria.png";
import Spain from "../../../media/hotel/frogbar_spain.png";
import USA from "../../../media/hotel/frogbar_usa.png";
import {
  SERVER_ENDPOINT,
  TON_EXPLORER,
  TON_EXPLORER_KEY,
} from "../../../utils/constants";
import { formatNumberWithCommas } from "../../../utils/formatedNumberWithComas";
import "./privatesale.css";
import { WalletContext } from "../../../context/WalletContext";

function createTransactionObject(amount) {
  const transaction = {
    validUntil: Math.floor(Date.now() / 1000) + 600,
    messages: [
      {
        address: "UQDFPqaHQKK_uZuU6pHhAGbKV2eOHYxjpKyfY5PlmW0l6sqp", // for test
        // address: "UQChJhuS12aL-kyiqu4onDhFapXIQ4MdJINN23KQ18sQRjAa",
        // UQA-d6zF28paUMDjQlGcjqxZ7elhm-haDIt2NNpLx0FJSCnj
        amount, // amount * 1e9 -> to string
        payload: "",
      },
    ],
  };
  return transaction;
}

const packages = [
  {
    name: "Espresso",
    value: 30,
    image: v3espresso,
  },
  {
    name: "Cappuccino",
    value: 50,
    image: v3cappucino,
  },
  {
    name: "Macchiato",
    value: 100,
    image: v3macchiato,
  },
  {
    name: "Ice Coffee",
    value: 200,
    image: v3iceCoffee,
  },
  {
    name: "Caffe Mocha",
    value: 500,
    image: v3caffeMocha,
  },
];
const imageMap = {
  Motorbike: v3motorBikeImg,
  SUVs: v3Suvis,
  Sports: sports,
  Luxury: Luxury,
  Jets: Jets,
  Yacht: Yacht,
};
const imageMapOfHotel = {
  China: China,
  Greece: Greece,
  Thailand: Thailand,
  Holland: Holland,
  Hawaii: Hawaii,
  Nigeria: Nigeria,
  Egypt: Egypt,
  Austria: Austria,
  Spain: Spain,
  USA: USA,
};
const BuyCoffeModal = ({
  setBuyCoffe,
  packageID,
  packageAmount,
  packageName,
  usdTonPrice,
  handleCoffeModalClose,
  userPackages,
  setIsTransactionProcessing,
}) => {
  const [txnActive, setTxnActive] = useState(false);
  const [logs, setLogs] = useState([
    { loading: true, error: false, message: "User wallet confirmation" },
  ]);
  const [tonConnectUI] = useTonConnectUI();
  const [tokenPrice, setTokenPrice] = useState(0.0);
  const [ton, setTon] = useState(0.0);
  const [tonPrice, setTonPrice] = useState(0.0);
  const { jwt } = useContext(AuthContext);
  const { setUser } = useContext(UserContext);
  const { userMotors } = useContext(MotorContext);
  const { userHotels } = useContext(HotelContext);
  const { sendActiveWalletTransaction, activeWallet, activeWalletAddress: userAddress } = useContext(WalletContext);

  console.log(userHotels, "=====");

  useEffect(() => {
    async function solPrice() {
      try {
        const response = await fetch(
          `https://dapp-prodapi.pepefrogbar.com/public-token-price`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        const parsedResponse = await response.json();
        console.log(parsedResponse);
        setTokenPrice(parsedResponse.tokenPrice);
      } catch (error) {
        console.log(error);
      }
    }
    async function tonPrice() {
      try {
        const response = await fetch(
          `${SERVER_ENDPOINT}/v1/appmetric/packages/value`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        const parsedResponse = await response.json();
        console.log(parsedResponse);
        setTon(parsedResponse.data.balanceTon);
      } catch (error) {
        console.log(error);
      }
    }

    solPrice();
    tonPrice();
    getTonUsdValue().then((price) => {
      setTonPrice(price);
    });
  }, []);

  function handleTxnFailure() { }
  function handleStepSuccess(message) {
    setLogs((logs) => {
      console.log(logs, "hewlo");
      const lastLog = logs.pop();
      return [...logs, lastLog, { loading: true, error: false, message }];
    });
  }

  function handleStepFailure() {
    setLogs((logs) => {
      const lastLog = logs.pop();
      return [...logs, lastLog];
    });
  }

  const getLatestTx = async (limit) => {
    try {
      // api key = ec2cd059d5d4ec929eaa7936442b3938cce4792a6834faa229c2100060f37c67
      const txResponse1 = await fetch(
        `${TON_EXPLORER}/getTransactions?limit=10&archival=true&address=${userAddress}`, // Fetch 10 transactions
        {
          method: "GET",
          headers: {
            "X-API-Key": TON_EXPLORER_KEY, // store in env
          },
        }
      );

      if (txResponse1.ok) {
        const tx = await txResponse1.json();

        if (limit === 1) {
          // Return only the first transaction (index 0)
          return {
            hash: tx?.result?.[0]?.transaction_id.hash,
            lt: tx?.result?.[0]?.transaction_id.lt,
          };
        } else if (limit === 10) {
          // Return the full list of transactions
          return tx.result.map((transaction) => ({
            hash: transaction?.transaction_id?.hash,
            lt: transaction?.transaction_id?.lt,
          }));
        }
      } else {
        console.error(
          "Failed to fetch transactions:",
          await txResponse1.text()
        );
        return null;
      }
    } catch (error) {
      console.error("Error fetching transactions:", error);
      return null;
    }
  };

  async function getTonUsdValue() {
    try {
      const response = await fetch(`${SERVER_ENDPOINT}/price/ton/usd`, {
        method: "GET",
      });
      const parsedResponse = await response.json();
      if (parsedResponse.status === "FAILED")
        throw new Error("Failed to fetch usd-ton price");
      return parseFloat(parsedResponse.data.usd);
    } catch (error) {
      console.log(error);
    }
  }

  async function handlePurchase() {
    // toast.success("Package purchase in progress");

    setTxnActive(true);
    setIsTransactionProcessing(true);
    // alert(`${userAddress} timeless`);
    const latestTx1 = await getLatestTx(1);
    console.log("Old tx: ", latestTx1);

    try {
      const price = await getTonUsdValue();
      packageAmount =
        parseFloat((packages[packageID].value / price).toFixed(9)) * 1e9;

      // packageAmount = parseFloat(0.0001) * 1e9; // remove for dev !!
      if (!userAddress) {
        handleCoffeModalClose();
        await tonConnectUI.connectWallet();
      }
      const resp = await sendActiveWalletTransaction(
        createTransactionObject(packageAmount)
      );
      // handleStepSuccess("Blockchain Transaction Confirmation");
      let latestTx2 = latestTx1;
      let counter = 0;
      const counterMax = 10;
      const pollingInterval = 6000;

      await sleep(pollingInterval);

      while (counter < counterMax) {
        latestTx2 = await getLatestTx(1);
        console.log("Old tx: ", latestTx1);
        console.log("Polling latest tx: ", latestTx2);

        if (latestTx2 && latestTx2.hash !== latestTx1?.hash) {
          // handleStepSuccess("Server Transaction Confirmation");
          console.log("Transaction successfully received", latestTx2);
          break;
        }

        counter++;
        await sleep(pollingInterval);
      }

      console.log(userAddress);

      if (latestTx2 && latestTx2.hash !== latestTx1?.hash) {
        try {
          const resp = await fetch(
            `${SERVER_ENDPOINT}/v1/transaction/success`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${jwt}`,
              },
              body: JSON.stringify({
                packageID: userPackages[packageID].packageID,
                latestTx2,
                userAddress,
                amount: packageAmount,
              }),
            }
          );

          if (resp.status === 200) {
            const parsedRes = await resp.json();
            setUser((user) => ({ ...user, ...parsedRes.data }));

            toast.success("Package purchased successfully");
          } else {
            toast.error("Api call failed");
          }
        } catch (error) {
          toast.error("Error updating package, please contact support.");
          // handleStepFailure();
          console.error("Backend error:", error);
        }
      } else {
        toast.error("Transaction could not be confirmed, storing as pending.");
      }
    } catch (error) {
      console.error("Failed to send transaction:", error);
      toast.error("Failed to send transaction: " + error.message);
    } finally {
      setTxnActive(false);
      setIsTransactionProcessing(false);
      handleCoffeModalClose();
    }
  }

  function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  return (
    <div style={{ padding: "15px 10px" }}>
      {!txnActive ? (
        <>
          <div className="buyCoffeeMain">
            <div className="coffeeImgSection">
              <img
                src={packages[packageID].image}
                alt=""
                style={{
                  height: "91px",
                  objectFit: "cover",
                }}
              />
              <img src={v3CoffeeTick} alt="" className="coffeeTick" />
            </div>
            <div className="buyCoffeeContent">
              <span className="buyCoffeeText">
                Confirm and get instantly your allocation into <br /> Pepe's
                Frogbar Private Sale, listing Q4 2024
              </span>
            </div>
            <div className="coffeeModalBlueBox">
              <span style={{ fontSize: "12px" }}>
                PRIVATE SALE PRICE INDEX:{" "}
                <span
                  style={{
                    color: "#2DE94D",
                  }}
                >
                  ${tokenPrice.toFixed(10)}
                </span>
              </span>
              <span style={{ fontSize: "12px" }}>
                TON NEEDED:{" "}
                <span
                  style={{
                    color: "#2DE94D",
                  }}
                >
                  {parseFloat(
                    (packages[packageID].value / tonPrice).toFixed(2)
                  )}{" "}
                  ton
                </span>
              </span>
              <span style={{ fontSize: "12px" }}>
                ALLOCATION:{" "}
                <span
                  style={{
                    color: "#2DE94D",
                  }}
                >
                  {formatNumberWithCommas(
                    (packages[packageID].value / tokenPrice).toFixed(2)
                  )}{" "}
                  Pepe Credits
                </span>
              </span>
            </div>
            {/* <div className="coffeeAmount">
              <span>${packages[packageID].value}</span>
            </div> */}
            <div className="featuresSection">
              <span
                style={{
                  textAlign: "left",
                }}
              >
                EXTRA IN-GAME FEATURES:
              </span>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                  marginTop: "5px",
                  overflow: "hidden",
                }}
              >
                <div className="coffeeFeaturesBoxes">
                  {userMotors.map(({ motorName, motorLevel }, idx) => {
                    // Determine if we should show green or silver tick based on packageID
                    const isGreenTick = packageID === 4 || idx <= packageID; // All green ticks if packageID is 4

                    return (
                      <div
                        key={idx}
                        className="coffeeFeaturesBox"
                        style={{ position: "relative" }}
                      >
                        {/* Position the tick at the top-right */}
                        <div
                          style={{
                            position: "absolute",
                            top: "-8px",
                            right: "-35px",
                            width: "100%", // Control the tick size
                          }}
                        >
                          <img
                            src={isGreenTick ? greentick : silvertick}
                            alt="tick"
                            style={{ width: "25%", height: "auto" }}
                          />
                        </div>
                        <span className="coffeeBoxTitle">
                          {motorName.toUpperCase()}
                        </span>
                        <div>
                          <img
                            src={imageMap[motorName] || sports} // Dynamic motor image or fallback
                            alt={motorName}
                            style={{ width: "90%", height: "90%" }}
                          />
                        </div>
                        <div className="coffeeBoxBottom">
                          <div>Lvl {1}</div>
                          <div>{`10m`}</div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "4px",
                            marginTop: "5px",
                            paddingBottom: "8px",
                          }}
                        >
                          <img
                            src={v3GoldIcon}
                            alt=""
                            height="15px"
                            width="15px"
                          />
                          <span style={{ fontSize: "9px" }}>{`50k`}</span>
                        </div>
                      </div>
                    );
                  })}
                  {userHotels?.map(({ hotelName, motorLevel }, idx) => {
                    // Determine if we should show green or silver tick based on packageID
                    const isGreenTick = packageID === 4 || idx <= packageID; // All green ticks if packageID is 4

                    return (
                      <div
                        key={idx}
                        className="coffeeFeaturesBox"
                        style={{ position: "relative" }}
                      >
                        {/* Position the tick at the top-right */}
                        <div
                          style={{
                            position: "absolute",
                            top: "-8px",
                            right: "-35px",
                            width: "100%", // Control the tick size
                          }}
                        >
                          <img
                            src={isGreenTick ? greentick : silvertick}
                            alt="tick"
                            style={{ width: "25%", height: "auto" }}
                          />
                        </div>
                        <span className="coffeeBoxTitle hotelName">
                          {hotelName?.toUpperCase()}
                        </span>
                        <div style={{ padding: "5px 0px" }}>
                          <img
                            src={imageMapOfHotel[hotelName] || China} // Dynamic motor image or fallback
                            alt={hotelName}
                            style={{ width: "100%", height: "100%" }}
                          />
                        </div>
                        <div className="coffeeBoxBottom">
                          <div>Lvl {1}</div>
                          <div>{`10m`}</div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "4px",
                            marginTop: "5px",
                          }}
                        >
                          <img
                            src={v3GoldIcon}
                            alt=""
                            height="15px"
                            width="15px"
                          />
                          <span style={{ fontSize: "9px" }}>{`50k`}</span>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          <div>
            <button className="stripe-button" onClick={handlePurchase}>
              PURCHASE
            </button>
          </div>
        </>
      ) : (
        <div
          style={{
            textAlign: "center",
            justifyContent: "center",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            margin: "30px 0",
          }}
        >
          <Grid
            visible={true}
            height="120"
            width="120"
            color="#aaa"
            ariaLabel="grid-loading"
            radius="12.5"
            wrapperStyle={{}}
            wrapperClass="grid-wrapper"
          />
          <span style={{ margin: "20px 0", fontSize: "20px" }}>
            Confirming Transaction...
          </span>
          <span style={{ margin: "5px 0", fontSize: "16px" }}>
            Dont close the popup
          </span>
        </div>
      )}
    </div>
  );
};

export default BuyCoffeModal;
