import React, { createContext, useState } from "react";

export const UserContext = createContext();

export const UserContextProvider = ({ children }) => {
    const [user, setUser] = useState({});
    const [userCredits, setUserCredits] = useState(0);
    const [userEnergy, setUserEnergy] = useState(0);
    const [showReturnPopup, setShowReturnPopup] = useState(false);


    return (
        <UserContext.Provider value={{ showReturnPopup, setShowReturnPopup, user, setUser, userCredits, setUserCredits, userEnergy, setUserEnergy }}>
            {children}
        </UserContext.Provider>
    )
}