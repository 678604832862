import React, { useContext, useState } from "react";
import toast from "react-hot-toast";
import { AuthContext } from "../../context/AuthContext";
import sports from "../../media/clubs & motors/dealer_comfort.png";
import austria from "../../media/hotels_img/hotel_austria.png";
import china from "../../media/hotels_img/hotel_china.png";
import egypt from "../../media/hotels_img/hotel_egypt.png";
import greece from "../../media/hotels_img/hotel_greece.png";
import hawaii from "../../media/hotels_img/hotel_hawai.png";
import holland from "../../media/hotels_img/hotel_holland.png";
import nigeria from "../../media/hotels_img/hotel_nigeria.png";
import spain from "../../media/hotels_img/hotel_spain.png";
import thailand from "../../media/hotels_img/hotel_thailand.png";
import usa from "../../media/hotels_img/hotel_usa.png";
import v3GoldCoin from "../../media/silver-pepe-bg.png";
import {
  hotelIdxToNameMapping,
  hotelLevelDetails,
  SERVER_ENDPOINT,
} from "../../utils/constants";
import { getReadableNumber } from "../../utils/helpers";
import "./club.css";

const imageMapHotel = {
  China: china,
  Greece: greece,
  Thailand: thailand,
  Holland: holland,
  Hawaii: hawaii,
  Nigeria: nigeria,
  Egypt: egypt,
  Austria: austria,
  Spain: spain,
  USA: usa,
};

const LevelUpHotel = ({
  handlePopupClose,
  selectedHotel,
  setUserHotels,
  userCredits,
  setUserCredits,
  selectedHotelLevel,
}) => {
  const [loading, setLoading] = useState(false);
  const { jwt } = useContext(AuthContext);

  async function handleConfirm() {
    try {
      const resp = await fetch(`${SERVER_ENDPOINT}/v1/hotel/upgradeHotel`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwt}`,
        },
        body: JSON.stringify({ hotelID: selectedHotel.hotelId }),
      });

      console.log("responce for hotel upgreade --------",resp)
      const parsedRes = await resp.json();
      if (resp.status === 200) {
        const hotels = parsedRes.updatedData.hotelLevel.map((level, idx) => ({
          hotelId: idx,
          hotelName: hotelIdxToNameMapping[idx],
          isOnUpgrade: parsedRes.updatedData.isOnUpgrade[idx],
          isUnlocked: parsedRes.updatedData.isUnLocked[idx],
          upgradeStartAt: parsedRes.updatedData.timeOfStart[idx],
          hotelLevel: level,
        }));
        setUserCredits(
          userCredits - hotelLevelDetails[selectedHotelLevel].invest
        );
        console.log("this is hotel", hotels);
        setUserHotels(hotels);

        toast.success("Hotel is upgrading...");
      } else {
        toast.error(parsedRes.message);
      }
    } catch (error) {
      console.log(error);
      toast.error(JSON.stringify(error));
    } finally {
      setLoading(false);
      handlePopupClose();
    }
  }

  return (
    <div className="LevelUpMotorContainer">
      <p className="levelUpTitle">LEVEL UP</p>
      <div className="levelUpContent">
        <div className="levelUpImgBox">
          <img
            src={imageMapHotel[selectedHotel.hotelName] || sports}
            alt=""
            className="levelUpImg"
          />
        </div>
      </div>
      <div className="levelUpTextContent">
        <p className="textContentTitle">New investments:</p>
        <div className="info">
          <div className="inlineInfo">
            <span className="infoText">Time :</span>
            <span className="InfoText">
              {Math.floor(
                hotelLevelDetails[selectedHotel.hotelLevel].duration / 60
              )}
              h {hotelLevelDetails[selectedHotel.hotelLevel].duration % 60}m
            </span>
          </div>
          <div className="inlineInfo">
            <span className="infoText">ROI :</span>
            <span className="InfoText">
              {hotelLevelDetails[selectedHotel.hotelLevel].roi}%
            </span>
          </div>
          <div className="inlineInfo">
            <span className="infoText">Investment:</span>
            <span
              className="InfoText"
              style={{ display: "flex", alignItems: "center", gap: "5px" }}
            >
              {getReadableNumber(
                hotelLevelDetails[selectedHotel.hotelLevel].invest
              )}
              <img src={v3GoldCoin} alt="" height="20px" width="20px" />
            </span>
          </div>
          <div className="inlineInfo">
            <span className="infoText">Eligible User Credits :</span>
            <span className="InfoText">{getReadableNumber(userCredits)}</span>
          </div>
        </div>
        <div className="Level_bottomSection">
          <button
            disabled={loading}
            onClick={handleConfirm}
            className="stripe-button"
          >
            {loading ? "Updating.." : "Confirm"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default LevelUpHotel;
