import { OKXTonConnectUI, THEME } from '@okxconnect/ui';
import React, { act, Children, createContext, useEffect, useState } from 'react';
import { useTonConnectUI } from "@tonconnect/ui-react";
import { toUserFriendlyAddress } from '@tonconnect/sdk';

export const WalletContext = createContext();

export default function WalletContextProvider({ children }) {
    const [okxUI, setOkxUI] = useState();
    const [tonConnectUI, setOptions] = useTonConnectUI();
    const [activeWallet, setActiveWallet] = useState(null);
    const [activeWalletAddress, setActiveWalletAddress] = useState(null);

    useEffect(() => {
        async function initOkx() {
            if (!customElements.get('okxc-root')) {
                const ui = new OKXTonConnectUI({
                    dappMetaData: {
                        name: "PepeFrogbar"
                    },
                    actionsConfiguration: {
                        returnStrategy: 'none',
                        tmaReturnUrl: 'back'
                    },
                    uiPreferences: {
                        theme: THEME.LIGHT
                    },
                    language: 'en_US',
                    restoreConnection: true
                })
                setOkxUI(ui);
            }
        }

        initOkx()
    }, [])

    useEffect(() => {
        if (tonConnectUI) {
            tonConnectUI.onStatusChange((connectedWallet) => {
                if (connectedWallet) {
                    setActiveWallet("ton");

                    setActiveWalletAddress(toUserFriendlyAddress(connectedWallet.account.address));
                }
            })
        }
        if (okxUI) {
            okxUI.onStatusChange((connectedWallet) => {
                if (connectedWallet) {
                    setActiveWallet("okx");
                    setActiveWalletAddress(toUserFriendlyAddress(connectedWallet.account.address));
                }
            })
        }
    }, [tonConnectUI, okxUI])


    async function sendActiveWalletTransaction(txnObject) {
        if (activeWallet === "okx") {
            await okxUI.sendTransaction(txnObject);
        }
        if (activeWallet === "ton") {
            await tonConnectUI.sendTransaction(txnObject);
        }
    }

    async function handleOkxConnect() {
        await okxUI.openModal();
    }
    async function handleTonConnect() {
        await tonConnectUI.openModal();
    }
    async function handleActiveWalletDisconnect() {
        if (activeWallet === "okx") await okxUI.disconnect()
        if (activeWallet === "ton") await tonConnectUI.disconnect();
        setActiveWallet(null);
        setActiveWalletAddress(null);
    }

    return (

        <WalletContext.Provider value={{ okxUI, activeWallet, activeWalletAddress, sendActiveWalletTransaction, handleOkxConnect, handleTonConnect, handleActiveWalletDisconnect }}>
            {children}
        </WalletContext.Provider>
    )
}
