import React, { useContext, useState } from 'react'
import { AuthContext } from '../../../context/AuthContext';
import { levelCreditPrice, SERVER_ENDPOINT } from '../../../utils/constants';
import { getReadableNumber } from "../../../utils/helpers";
import toast from 'react-hot-toast';
import { UserContext } from '../../../context/UserContext';

import coin from "../../../media/V3_coin.png";
import auth from "../../../media/V3_auth.png";

export default function MultiTap() {
    const { jwt } = useContext(AuthContext);
    const [isLoading, setIsLoading] = useState(false);

    const { setUser, setUserCredits, user } = useContext(UserContext);
    const { level } = user;

    async function handleLevelUpgrade() {
        setIsLoading(true);
        try {
            const purchaseRes = await fetch(
                `${SERVER_ENDPOINT}/v1/user/level/updgrade`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${jwt}`,
                    },
                }
            );

            const parsedRes = await purchaseRes.json();
            if (parsedRes.status === "SUCCESS") {
                setUser((user) => ({ ...user, level: parsedRes.data.level }));
                setUserCredits(parsedRes.data.credits);
                toast.success("Level Upgraded");
            } else throw new Error("Failed to updgrade level");
        } catch (error) {
            console.log(error);
            toast.error("Failed");
        } finally {
            setIsLoading(false);
        }
    }
    return (
        <button
            disabled={isLoading}
            onClick={handleLevelUpgrade}
            style={{
                display: "flex",
                width: "100%",
                border: "none",
                alignItems: "center",
                backgroundColor: "#333333",
                height: "6rem",
                borderRadius: "20px",
                marginTop: "1.5rem",
            }}
        >
            <img src={auth} alt="auth" style={{ margin: "0 0.5rem 0 1rem" }} />
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    gap: "0.2rem",
                }}
            >
                <div
                    style={{
                        fontSize: "1.5rem",
                        color: "white",
                        fontFamily: "roboto",
                        fontWeight: "400",
                    }}
                >
                    Multi Tap
                </div>
                <div
                    style={{
                        fontSize: "1rem",
                        color: "#575757",
                        fontFamily: "roboto",
                        fontWeight: "400",
                    }}
                >
                    {level === 9 ? "Cannot Boost" : "Boosts Earnings per Tap"}
                </div>
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "0.4rem",
                    }}
                >
                    {isLoading ? <div style={{ color: "#C6B4B4" }}>Updating...</div> : (
                        <>
                            <img src={coin} alt={"coin"} style={{ marginTop: "0.4rem" }} />
                            <span style={{ color: "#C6B4B4" }}>
                                {getReadableNumber(levelCreditPrice[level + 1])}
                            </span>{" "}
                            <span style={{ color: "#C6B4B4" }}>• {level + 1} LVL</span>
                        </>
                    )}
                </div>
            </div>
        </button>
    )
}
