import React, { createContext, useEffect, useMemo, useState } from "react";
import useWebSocket from "../hooks/useWebhook";
import { SERVER_ENDPOINT } from "../utils/constants";

export const AuthContext = createContext();




export const AuthContextProvider = ({ children }) => {
    const [jwt, setJwt] = useState();
    const [taskPending, setTaskPending] = useState(0);
    const [taskPendingX, setTaskPendingX] = useState(0);
    const [taskPendingYT, setTaskPendingYT] = useState(0);
    const [taskPendingTele, setTaskPendingTele] = useState(0);
    
    const [notification, setNotification] = useState(null);
    const { sendMessage } = useWebSocket({ onMessage: handleWsResponse });


    function handleWsResponse(message) {
        try {
            const data = JSON.parse(message.data);
            if (data.type === "notification") {
                setNotification(data.amount);
            }
        } catch (error) {
            console.log(error);
        }
    };
    useEffect(() => {
        let t1;
        if (notification) {
            t1 = setTimeout(() => setNotification(0), 4000)
        }
        return () => clearTimeout(t1);
    }, [notification])
    useEffect(() => {
        async function fetchTaskStatus() {
            try {
                if (jwt) {
                    const res = await fetch(`${SERVER_ENDPOINT}/v1/task/count`, {
                        method: "GET",
                        headers: {
                            Authorization: `Bearer ${jwt}`,
                        },
                    });
                    const parsed = await res.json();
                    if (parsed.status === "SUCCESS") {
                        const data = parsed.data;
                        // console.log("response", parsed.data);
                        setTaskPending(data.pendingTasksCountX+data.pendingTasksCountYT);
                        setTaskPendingX(data.pendingTasksCountX);
                        setTaskPendingYT(data.pendingTasksCountYT);
                        setTaskPendingTele(data.pendingTasksCountTele);
                    }
                    else throw new Error("Failed to fetch task status");
                }
            } catch (error) {
                console.log(error);
            }
        }
        if (jwt) {
            sendMessage(JSON.stringify({ type: "presence", jwt }));
            fetchTaskStatus();
        }
    }, [jwt])

    return (
        <AuthContext.Provider value={{ jwt, setJwt, taskPending, setTaskPending, notification, taskPendingX, taskPendingYT, taskPendingTele }}>
            {children}
        </AuthContext.Provider>
    )
}