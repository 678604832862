import React from "react";
import { useNavigate } from "react-router-dom";
import purchesArrow from "../../../media/v3_purcheceArrow.png";
import premiumTick from "../../../media/v3_tick3.png";

const Premium = () => {
  const navigate = useNavigate();

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <img
        src={premiumTick}
        alt=""
        height="80px"
        width="80px"
        style={{ objectFit: "cover" }}
      />
      <span
        style={{
          fontSize: "20px",
          fontWeight: "bold",
          color: "#c9c9c9",
          fontFamily: "roboto",
        }}
      >
        PREMIUM MEMBERS ONLY
      </span>{" "}
      <br />
      <span
        style={{
          fontFamily: "roboto",
          fontSize: "16px",
          fontWeight: "400",
          color: "#c9c9c9",
          textAlign: "center",
          margin: "0px 10%",
        }}
      >
        To access the Motor and Hotel NFTs, please upgrade to a Premium
        Membership and Enjoy exclusive benefits and unlock the full potential
        of our Platform.
      </span>
      <span
        style={{
          display: "flex",
          alignItems: "center",
          color: "#BFA75D",
          fontSize: "16px",
          fontWeight: "bold",
          marginTop: "10px",
        }}
        onClick={() => {
          navigate("/privatesale");
        }}
      >
        Purchase Now <img src={purchesArrow} alt="" />{" "}
      </span>
    </div>
  );
};

export default Premium;
