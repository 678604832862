import React, { useContext, useState } from "react";
import "./club.css";
import levelUpImg from "../../media/v3_LevelUp.png";
import v3GoldCoin from "../../media/silver-pepe-bg.png";
import {
  motorIdxToNameMapping,
  motorLevelDetails,
  SERVER_ENDPOINT,
} from "../../utils/constants";
import { getReadableNumber } from "../../utils/helpers";
import { AuthContext } from "../../context/AuthContext";

import mumbai from "../../media/clubs & motors/frogbar_mumbai.png";
import istanbul from "../../media/clubs & motors/frogbar_istanbul.png";
import dubai from "../../media/clubs & motors/frgobar_dubai.png";
import beijin from "../../media/clubs & motors/frogbar_bejing.png";
import paris from "../../media/clubs & motors/frogbar_paris.png";
import london from "../../media/clubs & motors/frogbar_london.png";
import roma from "../../media/clubs & motors/frogbar_roma.png";
import rio from "../../media/clubs & motors/frogbar_RIO.png";
import moscow from "../../media/clubs & motors/frogbar_moscow.png";
import singapore from "../../media/clubs & motors/frogbar_singapore.png";
import v3motorBikeImg from "../../media/clubs & motors/dealer_motorcycle.png";
import sports from "../../media/clubs & motors/dealer_comfort.png";
import v3Suvis from "../../media/clubs & motors/dealer_suvs.png";
import Luxury from "../../media/clubs & motors/dealer_luxury.png";
import Jets from "../../media/clubs & motors/dealer_private_jets.png";
import Yacht from "../../media/clubs & motors/dealer_yatch_club.png";
import toast from "react-hot-toast";

const imageMap = {
  dubai: dubai,
  beijin: beijin,
  mumbai: mumbai,
  istanbul: istanbul,
  paris: paris,
  london: london,
  roma: roma,
  rio: rio,
  moscow: moscow,
  singapore: singapore,
  la: moscow,
  miami: roma,
  Motorbike: v3motorBikeImg,
  SUVs: v3Suvis,
  Sports: sports,
  Luxury: Luxury,
  Jets: Jets,
  Yacht: Yacht,
};

const LevelUpMotor = ({
  handlePopupClose,
  selectedMotor,
  setUserMotors,
  userCredits,
  setUserCredits,
  selectedMotorLevel,
}) => {
  const [loading, setLoading] = useState(false);
  const { jwt } = useContext(AuthContext);

  async function handleConfirm() {
    try {
      const resp = await fetch(`${SERVER_ENDPOINT}/v1/motor/upgrade`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwt}`,
        },
        body: JSON.stringify({ motorID: selectedMotor.motorId }),
      });

      const parsedRes = await resp.json();
      if (resp.status === 200) {
        const motors = parsedRes.updatedData.motorLevel.map((level, idx) => {
          return {
            motorId: idx,
            motorName: motorIdxToNameMapping[idx],
            isOnUpgrade: parsedRes.updatedData.isOnUpgrade[idx],
            isUnlocked: parsedRes.updatedData.isUnLocked[idx],
            upgradeStartAt: parsedRes.updatedData.timeOfStart[idx],
            motorLevel: level,
          };
        });
        setUserCredits(
          userCredits - motorLevelDetails[selectedMotorLevel].invest
        );
        setUserMotors(motors);

        toast.success("Motor is upgrading...");
      } else {
        toast.error(parsedRes.message);
      }
    } catch (error) {
      console.log(error);
      toast.error(JSON.stringify(error));
    } finally {
      setLoading(false);
      handlePopupClose();
    }
  }
  return (
    <div className="LevelUpMotorContainer">
      <p className="levelUpTitle">LEVEL UP</p>
      <div className="levelUpContent">
        <div className="levelUpImgBox">
          {/* <span className="BoxTitle">PEPE's </span>
          <span className="LevlUpSubTitle">{selectedMotor.motorName}</span> */}
          <img
            src={imageMap[selectedMotor.motorName] || sports}
            alt=""
            className="levelUpImg"
          />
        </div>
      </div>
      <div className="levelUpTextContent">
        <p className="textContentTitle">New investments:</p>
        <div className="info">
          <div className="inlineInfo">
            <span className="infoText">Time :</span>
            <span className="InfoText">
              {Math.floor(
                motorLevelDetails[selectedMotor.motorLevel].duration / 60
              )}
              h {motorLevelDetails[selectedMotor.motorLevel].duration % 60}m
            </span>
          </div>
          <div className="inlineInfo">
            <span className="infoText">ROI :</span>
            <span className="InfoText">
              {motorLevelDetails[selectedMotor.motorLevel].roi}%
            </span>
          </div>
          <div className="inlineInfo">
            <span className="infoText">Investment:</span>
            <span
              className="InfoText"
              style={{ display: "flex", alignItems: "center", gap: "5px" }}
            >
              {getReadableNumber(
                motorLevelDetails[selectedMotor.motorLevel].invest
              )}
              <img src={v3GoldCoin} alt="" height="20px" width="20px" />
            </span>
          </div>
          <div className="inlineInfo">
            <span className="infoText">Eligible User Credits :</span>
            <span className="InfoText">{getReadableNumber(userCredits)}</span>
          </div>
        </div>
        <div className="Level_bottomSection">
          <button
            disabled={loading}
            onClick={handleConfirm}
            className="stripe-button"
          >
            {loading ? "Updating.." : "Confirm"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default LevelUpMotor;
