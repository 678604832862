import React, { useContext, useState } from "react";
import { UserContext } from "../../../context/UserContext";
import bigWin from "../../../media/v3_bigWin.png";
import goodWin from "../../../media/v3_goodWin.png";
import massiveWin from "../../../media/v3_massiveWin.png";
import megaWin from "../../../media/v3_megaWin.png";

import Confetti from "react-confetti";

const image = {
  bronze: goodWin,
  silver: bigWin,
  gold: massiveWin,
  frog: megaWin,
};

const Congratulation = ({ onClose, Result, updatedCredits }) => {
  const [congo, setCongo] = useState(false);

  const { userCredits, setUserCredits } = useContext(UserContext);

  console.log(Result);
  // const incrementCredits = (newCredits) => {
  //   const difference = updatedCredits - userCredits;
  //   let currentCredits = userCredits;
  //   const increment = Math.ceil(difference / 50); // Number of steps for the animation

  //   const interval = setInterval(() => {
  //     currentCredits += increment;
  //     if (currentCredits >= newCredits) {
  //       currentCredits = newCredits;
  //       clearInterval(interval);
  //     }
  //     setDisplayedCredits(currentCredits);
  //   }, 30); // Speed of animation in ms
  // };
  const handleCollect = () => {
    setUserCredits(updatedCredits); // Update the user's credits
    // incrementCredits(updatedCredits); // Animate the increment
    if (navigator.vibrate) {
      navigator.vibrate(50);
    } else if (window.Telegram?.WebApp) {
      window.Telegram?.WebApp.HapticFeedback.notificationOccurred("success");
    }
    setCongo(true);
    setTimeout(() => {
      onClose();
    }, 3000);
  };

  return (
    <div style={{ padding: "20px" }}>
      {congo && (
        <div className="game_2_confeti">
          <Confetti width={300} recycle={false} />
        </div>
      )}

      <div
        style={{
          position: "relative",
          width: "340px",
          height: "288px",
          marginLeft: "12px",
        }}
      >
        <img src={image[Result]} alt="" width="100%" height="100%" />
      </div>
      <button
        className="stripe-button"
        style={{
          marginTop: "15px",
        }}
        onClick={() => handleCollect()}
      >
        Collect
      </button>
    </div>
  );
};

export default Congratulation;
