import React, { useContext, useEffect, useState } from "react";
import styles from "./Friend.module.css";

import { animated, useSpring } from "@react-spring/web";
import { useTonAddress } from "@tonconnect/ui-react";
import toast from "react-hot-toast";
import { useSearchParams } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";
import { ClubContext } from "../../../context/ClubContext.js";
import { HotelContext } from "../../../context/HotelContext.js";
import { MotorContext } from "../../../context/MotorContext";
import { UserContext } from "../../../context/UserContext";
import blueDiamondIcon from "../../../media/blueDiamond.png";
import bronzeIcon from "../../../media/bronze.png";
import crownDiamondIcon from "../../../media/crownDiamond.png";
import dancingDuck from "../../../media/dancing-duckdancing.gif";
import diamondIcon from "../../../media/diamond.png";
import goldIcon from "../../../media/gold.png";
import Loader from "../../../media/Loader.svg";
import platinumIcon from "../../../media/platinum.png";
import {
  default as creditCoinGif,
  default as v3_GoldIcon,
  default as v3GoldCoin,
} from "../../../media/silver-pepe-bg.png";
import silverIcon from "../../../media/silver.png";
import v3_InviteIcon from "../../../media/v3_inviteIcon.png";
import v3ProfileIcon from "../../../media/v3_profileIcon.png";
import v3VerifyIcon from "../../../media/v3_tick.png";
import {
  MINIAPP_LINK,
  SERVER_ENDPOINT,
  userClubToRankNameMapping,
} from "../../../utils/constants";
import { formatNumberWithCommas } from "../../../utils/formatedNumberWithComas.js";
import {
  getMobileOperatingSystem,
  getReadableNumber,
  truncateName,
} from "../../../utils/helpers";
import BottomButtons from "../../Shared/BottomNavigation/bottomButtons";
import ProfileBar from "../../Shared/ProfileBar/ProfileBar";
import SettingsPopup from "../../Shared/ProfileBar/SettingsPopup.jsx";
import WalletModal from "../WalletModal";
import BonusList from "./BonusList";
import useTelegramBackButton from "../../../utils/backButton.js";

const rankIcons = {
  1: bronzeIcon,
  2: silverIcon,
  3: goldIcon,
  4: platinumIcon,
  5: diamondIcon,
  6: blueDiamondIcon,
  7: crownDiamondIcon,
};

const Friend = () => {
    useTelegramBackButton();
  const { user, userCredits, showConnectWallet, setShowConnectWallet } =
    useContext(UserContext);
  const { referralCode } = user;
  const [selectedOption, setSelectedOption] = useState(null);
  const [sortBy, setSortBy] = useState("credits");
  // const [friends, setFriends] = useState(friendsData);
  const [page, setPages] = useState("friend");
  const [visible, setVisible] = useState(true);
  const [tasks, setTasks] = useState([]);
  const [isSettings, setIsSettings] = useState(false);
  const [referredUsers, setReferredUsers] = useState([]);
  const [showList, setShowList] = useState(false);
  const connectedAddress = useTonAddress();
  const { jwt } = useContext(AuthContext);
  const { clubCredits, userClubs, setUserClubs, userStars, userClubRank } =
    useContext(ClubContext);
  const { userMotors, setUserMotors, motorCredits } = useContext(MotorContext);
  const { userHotels, setUserHotels, hotelCredits } = useContext(HotelContext);

  const [loading, setLoading] = useState(false);

  const topAnimation = useSpring({
    from: { transform: "translateY(-100%)", opacity: 0 },
    to: async (next, cancel) => {
      await next({ opacity: 1 });
      await next({
        transform: visible ? "translateY(0%)" : "translateY(1%)",
      });
    },
    config: { duration: 300 },
  });

  const bottomAnimation = useSpring({
    from: { transform: "translateY(100%)", opacity: 0 },
    to: async (next, cancel) => {
      await next({ opacity: 1 });
      await next({
        transform: visible ? "translateY(0%)" : "translateY(1%)",
      });
    },
    config: { duration: 300 },
  });

  const leftAnimation = useSpring({
    from: { transform: "translateX(-100%)", opacity: 0 },
    to: async (next, cancel) => {
      await next({ opacity: 1 });
      await next({
        transform: visible ? "translateX(0%)" : "translateX(0%)",
      });
    },
    config: { duration: 400 },
  });

  const rightAnimation = useSpring({
    from: { transform: "translateX(100%)", opacity: 0 },
    to: async (next, cancel) => {
      await next({ opacity: 1 });
      await next({
        transform: visible ? "translateX(0%)" : "translateX(0%)",
      });
    },
    config: { duration: 400 },
  });

  const handleSettingClose = () => {
    setIsSettings(false);
  };

  // const handleOptionClick = (index) => {
  //   setSelectedOption(index);
  // };
  useEffect(() => {
    async function fetchReferredUsers() {
      setLoading(true);
      try {
        const response = await fetch(
          `${SERVER_ENDPOINT}/v1/user/referredUsers`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${jwt}`,
            },
          }
        );
        const parsedResponse = await response.json();
        if (parsedResponse.status === "SUCCESS") {
          setReferredUsers(parsedResponse.data);
          console.log("referred", referredUsers);
          setLoading(false);
        } else {
          setLoading(false);
          throw new Error("Failed to fetch referred users");
        }
      } catch (error) {
        setLoading(false);
      }
    }
    async function fetchTasks() {
      try {
        const response = await fetch(`${SERVER_ENDPOINT}/v1/task/list`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwt}`,
          },
          body: JSON.stringify({ screen: "invite" }),
        });
        const parsedResponse = await response.json();
        if (parsedResponse.status === "SUCCESS") {
          setTasks(
            parsedResponse.data.sort(
              (t1, t2) => parseInt(t1.reward) - parseInt(t2.reward)
            )
          );
        } else throw new Error("Failed to redeem task");
      } catch (error) {
        console.log(error);
      }
    }
    fetchTasks();
    fetchReferredUsers();
  }, []);

  const handleSortClick = (sortKey) => {
    setSortBy(sortKey);
    const sortedFriends = [...referredUsers].sort((a, b) => {
      if (sortKey === "credits") {
        return b.credits - a.credits;
      } else {
        return b.rank - a.rank;
      }
    });
    setReferredUsers(sortedFriends);
  };

  let credits = 0;

  const forwardMsg = `Hi Amigo!

  Join me in the Pepe Tapping Game and let's have some fun! 🚀
  
  👆 Tap away to earn Pepe Tap Credits and unlock awesome rewards!
  
  🔥 Let's compete and see who can get the highest score!
  
  🔗 Click the link below to join my friend list and start tapping:
  ${MINIAPP_LINK}?startapp=${referralCode}
  
  🎉 Invite your friends and earn more Pepe Tap Credits together. The more, the merrier!
  
  🌟 Special Bonus: Get extra credits when you refer friends!`;

  const [searchParams] = useSearchParams();
  const bonus = searchParams.get("bonus");

  useEffect(() => {
    if (bonus) {
      setShowList(true);
    }
  }, [bonus]);

  return (
    <div className={styles.body}>
      <div className={styles.statContent}>
        <animated.div style={{ ...topAnimation }}>
          <div className={styles.pfBar} style={{ marginTop: "12px" }}>
            <ProfileBar setIsSettings={setIsSettings} />
            <div className="task_AmountSection">
              <img src={creditCoinGif} alt="" height="60px" width="60px" />
              <span className="task_amount">
                {formatNumberWithCommas(
                  userCredits +
                    Math.floor(clubCredits) +
                    Math.floor(motorCredits) +
                    Math.floor(hotelCredits)
                )}
              </span>
            </div>
          </div>
        </animated.div>
        <div className={styles.shareFriends}>
          <div>
            <span className={styles.friendsTitle} style={{ marginTop: "10px" }}>
              SHARE WITH FRIENDS
            </span>
            <div className={styles.friendsEarnDiv}>
              <div className={styles.friendsEarn}>
                <div className={styles.friendCredits}>
                  <img src={v3GoldCoin} alt="" height="35px" width="35px" />
                  <span>+20K</span>
                </div>{" "}
                <div>
                  <span className={styles.earnBoxText}>
                    For Non- premium User
                  </span>
                </div>
              </div>
              <div className={styles.friendsEarn}>
                <div className={styles.friendCredits}>
                  <img src={v3GoldCoin} alt="" height="35px" width="35px" />
                  <span>+30K</span>
                </div>{" "}
                <div>
                  <span className={styles.earnBoxText}>For Premium User</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        {!showList ? (
          <>
            <div className={styles.viewMore} onClick={() => setShowList(true)}>
              <span>VIEW MORE BONUSES</span>
            </div>
            <div className={styles.inviteDiv}>
              <div
                onClick={() => {
                  if (getMobileOperatingSystem() == "iOS") {
                    window.location.href = `https://t.me/share/url?url=${encodeURIComponent(
                      forwardMsg
                    )}`;
                  } else {
                    window.open(
                      `https://t.me/share/url?url=${encodeURIComponent(
                        forwardMsg
                      )}`
                    );
                  }
                }}
                className={styles.textDiv}
              >
                <span>Invite Friends</span>
              </div>
              <div
                onClick={() =>
                  navigator.clipboard
                    .writeText(`${MINIAPP_LINK}?startapp=${referralCode}`)
                    .then(() => toast.success("Copied"))
                }
                className={styles.inviteIconDiv}
              >
                <img
                  src={v3_InviteIcon}
                  alt="copy icon"
                  className={styles.copyIcon}
                />
              </div>
            </div>
            <div className={styles.invitedBox}>
              <animated.div style={{ ...bottomAnimation }}>
                <div className={styles.invitedHeader}>
                  <span>Friend list ({referredUsers.length})</span>
                </div>
                <div className={styles.friendListDiv}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      padding: "4px",
                    }}
                  >
                    <span>User</span>
                    <span style={{ width: "35%" }}>Credits</span>
                  </div>
                  <div className={styles.friendListScrollable}>
                    {loading ? ( // Show loader while loading
                      <div
                        className={styles.loaderContainer}
                        style={{
                          marginTop: "2rem",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src={Loader}
                          alt="Loading..."
                          className={styles.loader}
                          style={{ margin: "auto", width: "5rem" }}
                        />
                      </div>
                    ) : referredUsers.length > 0 ? (
                      referredUsers.map(
                        (
                          { name, credits, clubRank, telegramUsername },
                          idx
                        ) => (
                          <div key={idx} className={styles.userLists}>
                            <div className={styles.listRow}>
                              <img
                                src={v3ProfileIcon}
                                alt=""
                                className={styles.listProfileIcon}
                              />
                              <div>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginBottom: "3px",
                                  }}
                                >
                                  <span className={styles.userName}>
                                    {truncateName(
                                      name || telegramUsername || "User"
                                    )}
                                  </span>
                                  <img
                                    src={v3VerifyIcon}
                                    alt=""
                                    width="15px"
                                    height="15px"
                                  />{" "}
                                </div>
                                <span
                                  style={{
                                    color: "#C9C9C9",
                                    textAlign: "left",
                                  }}
                                >
                                  {userClubToRankNameMapping[clubRank]}
                                </span>
                              </div>
                            </div>
                            <div className={styles.creditsBox}>
                              <img
                                src={v3_GoldIcon}
                                alt=""
                                height="35px"
                                width="35px"
                              />
                              <div>
                                <span style={{ marginLeft: "5px" }}>
                                  {getReadableNumber(credits)}
                                </span>
                              </div>
                            </div>
                          </div>
                        )
                      )
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "100%",
                          height: "80%",
                          flexDirection: "column",
                        }}
                      >
                        <img
                          src={dancingDuck}
                          alt=""
                          height="100px"
                          width="100px"
                        />{" "}
                        <br />
                        <span>You have not invited anyone</span>
                      </div>
                    )}
                  </div>
                </div>
              </animated.div>
            </div>
          </>
        ) : (
          <div style={{ width: "95vw" }}>
            <BonusList setShowList={setShowList} />
          </div>
        )}

        <WalletModal
          connectedAddress={connectedAddress}
          showConnectWallet={showConnectWallet}
          setShowConnectWallet={setShowConnectWallet}
        />
        <div className={styles.bottomNav}>
          <BottomButtons page={page} setPages={setPages} />
        </div>

        {isSettings && (
          <SettingsPopup isOpen={isSettings} onClose={handleSettingClose} />
        )}
      </div>
    </div>
  );
};

export default Friend;
